import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Offer } from '../_models/offer';

@Injectable({ providedIn: 'root' })
export class ReviewService {
    constructor(
        private router: Router,
        private http: HttpClient
    ) {}
    
    single(id: any): any {
    	return this.http.get<any[]>(`${environment.apibase}review/${id}`);
    }
    
    sendReviewForParticipation(id: any, formValues:any): any {
    	return this.http.post(`${environment.apibase}review/add/${id}`, formValues);
    }
    
    list(): any {
    	return this.http.get<any[]>(`${environment.apibase}reviews`, {});
    }
}
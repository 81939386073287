import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '../_services/account.service';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (this.accountService.checkToken()) {
          let alreadyForcedToRedirect = false;
          let navigateTo = null;
          let currentPath = route.routeConfig?.path;
      	  
          // Si l'utilisateur n'as pas déjà rensigné ses informations de contact, il doit les saisir,
          // On le redirige de force vers la page d'édition des paramètres
          if (this.accountService.tokens.gaveTheirInfos !== true) {
          	console.log('MUST GIVE INFOS');
          	navigateTo = ['/my-account/infos'];
          }
          
          // Si les CGUs ont changées,
          // On le redirige de force vers la page d'acceptation des cgus
          if (this.accountService.tokens.lastAcceptedCguVersion !== this.accountService.tokens.lastCguVersion) {
            console.log('MUST ACCEPT CGUS');
            navigateTo = ['/new-cgus'];
          }
          
          // Si l'utilisateur a expiré
          // On le redirige de force vers la page de renouvellement
          let expiryDate = new Date(this.accountService.tokens.expiry);
          if( expiryDate < new Date() )
          {
          	console.log('MUST RENEW');
          	navigateTo = ['/my-account/renew'];
          }
          
          if( navigateTo )
          {
        	  if( '/' + currentPath !== navigateTo[0] )
        	  {
        		  console.log( "must navigate" );
		          //this.router.navigate(navigateTo); // Obsolete car probleme de rechargement
		          // Force le reload de l'app
		          window.document.location.href = '#' + navigateTo[0];
        	  }
          }
          
          return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, filter, take, map, finalize } from 'rxjs/operators';

import { AccountService } from '@app/_services/account.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	
    constructor(private accountService: AccountService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        return next.handle(request).pipe(catchError(err => {
        	// Pas de renew pour les requetes peandant le login
            if (err instanceof HttpErrorResponse && (!request.url.includes('login_check') )) {
                if( err.status === 401 ) {
                  // Si on a une 401 durant le renew
                  if( request.url.includes('renew') )
                  {
                    this.accountService.logout();
                  }
	              return this.handle401Error(request, next, err);
                }
            }
            
            return throwError(err.error);
        }))
    }
    
    private handle401Error(request: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse) {
      
      console.log( 'HANDLE 401', request );
      if (!this.isRefreshing) {
	      console.log( 'START REFRESH' );
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        let tokens = this.accountService.tokens;
        
        if( tokens.refresh_token )
        {
		    //console.log( 'USE REFRESH', tokens );
	        return this.accountService.refresh(tokens).pipe(
	        	switchMap((token: any) => {
				    //console.log( 'REFRESH OK', token );
                    this.isRefreshing = false;
				
				    this.accountService.saveToken(token);
				    this.refreshTokenSubject.next(token.token);
				    
				    return next.handle(this.addTokenHeader(request, token.token));
				}),
				catchError((err) => {
				    //console.log( 'REFRESH NOK' );
                    this.isRefreshing = false;
                    
                    if(err.status === 401)
                    {
	                    this.accountService.logout();
                    }
                    
				    return throwError(err.error);
				}),
				finalize(() => {
				    //console.log( 'END' );
                    this.isRefreshing = false;
                })
	        );
        }
      }
      
      return this.refreshTokenSubject.pipe(
	      filter(token => token !== null),
	      take(1),
	      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
      );
    }
    
    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';

@Component({
  templateUrl: './lostpassword.component.html',
})
export class LostPasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  env = environment;
  success = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService
  ) {
    this.form = this.formBuilder.group({
        username: ['', Validators.required]
    });
  }
  
  ngOnInit() {
  }

  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
      this.submitted = true;
      
      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.lostpassword(this.f['username'].value)
          .pipe(first())
          .subscribe(
              data => {
                //this.alertService.success('');
                //this.router.navigate(['']);
                this.success = true;
              },
              err => {
            	  this.alertService.error('Une erreur est survenue');
                  
                  // hightlight des erreurs
                  for( let i in err.errors )
                  {
                    let error = {};
                    Object.defineProperty( error, err.errors[i].message, {
                    	  value: true,
                    	  writable: false
                    } );
                    
                    this.f[err.errors[i].property].setErrors( error );
                  }
                  
                  this.loading = false;
              });
  }
}

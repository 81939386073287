<div class="container">
  <div class="login-box">
    <div class="text-center">
        <h1 class="title mb-2 mb-sm-4">MOT DE PASSE</h1>
        
        <div class="text-content big tag-line mb-3 mb-sm-5">
          Merci de redéfinir votre mot de passe contenant au minimum 6 caractères et intégrant au moins une minuscule, une majuscule, un chiffre et un caractère spécial (@$!%*?&).
        </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
          <div *ngIf="submitted && f['hash'].errors" class="alert alert-danger">
            <div *ngIf="f['hash'].errors['non-existing-link']">Lien de redéfinition de mot de passe incorrecte ou expiré</div>
          </div>
          <div class="form-group mb-2">
              <label for="password">Nouveau mot de passe</label>
              <passwordfield [formGroup]="form" [form]="f" fieldName="password" [submitted]="submitted"></passwordfield>
              <div *ngIf="submitted && f['password'].errors" class="text-danger">
                  <div *ngIf="f['password'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['password'].errors['bad-pattern']">Le mot de passe ne respecte pas le format obligatoire</div>
              </div>
          </div>
          <div class="form-group mb-4">
              <label for="password2">Répéter le mot de passe</label>
              <passwordfield [formGroup]="form" [form]="f" fieldName="password2" [submitted]="submitted"></passwordfield>
              <div *ngIf="submitted && f['password2'].errors" class="text-danger">
                  <div *ngIf="f['password2'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['password2'].errors['not-the-same']">Les mots de passe ne sont pas identiques</div>
              </div>
          </div>
          <div class="mb-2 text-center">
            <button [disabled]="loading" class="btn btn-primary">
                Enregistrer
            </button>
          </div>
      </form>
  </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { of } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Actu } from '@model/actu';
import { ReviewService } from '@services/review.service';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  //styleUrls: ['./actu-list.component.scss']
})
export class ReviewListComponent implements OnInit {

  env = environment;
  public reviews:any = []; // Actus affichées
  loading = false;

  constructor( private http: HttpClient, private reviewService: ReviewService, private formBuilder: FormBuilder ) {
  }

  ngOnInit(): void {
    this.getReviewsList();
  }
  
  ngOnDestroy(): void {
  }
  
  getReviewsList() {
	  this.loading = true;
	  
    this.reviewService.list().subscribe((data: any) => {
      this.reviews = data.data;
    });
  }
  
  getThumbnailSrc( actu: Actu ) {
    return `${this.env.filebase}actus/${actu.picture}`;
  }
  
  getBackgroundThumbnailStyle( actu: Actu ) {
    return `background-image: url(${this.env.filebase}actus/${actu.picture});`;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { of } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Actu } from '@model/actu';
import { ActuService } from '@services/actu.service';

import { CarouselComponent } from '@components/carousel.component';

@Component({
  selector: 'app-actu-list',
  templateUrl: './actu-list.component.html',
  //styleUrls: ['./actu-list.component.scss']
})
export class ActuListComponent implements OnInit {

  env = environment;
  public actus : Actu[] = []; // Actus affichées
  public sliderActus : Actu[] = [];
  loading = false;
  form: FormGroup;

  constructor( private http: HttpClient, private actuService: ActuService, private formBuilder: FormBuilder ) {
    this.form = this.formBuilder.group({
      searchTerm: ['']
    });
  }

  ngOnInit(): void {
    this.getActusList('');
    this.getSliderActusList();
  }
  
  ngOnDestroy(): void {
  }
  
  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  getActusList(searchTerm:any = '') {
	  this.loading = true;
	  
    this.actuService.list('list', searchTerm).subscribe((data: any) => {
      // mise à jour de la liste des offres
      let res = data.data;
      this.actus = [...res];
		  this.loading = false;
    });
  }
  
  getSliderActusList() {
    this.actuService.list('slider').subscribe((data: any) => { let res2 = data.data; this.sliderActus = [...res2]; });
  }
  
  getThumbnailSrc( actu: Actu ) {
    return `${this.env.filebase}actus/${actu.picture}`;
  }
  
  getBackgroundThumbnailStyle( actu: Actu ) {
    return `background-image: url(${this.env.filebase}actus/${actu.picture});`;
  }
  
  submitSearch()
  {
  	if( this.loading )
  	{
  		return;
  	}
  	
  	console.log( this.form.value['searchTerm'] );
  	
  	this.getActusList( this.form.value['searchTerm'] );
  }
}

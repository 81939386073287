<div class="container">
  <div class="btn btn-secondary arrow return mb-4 mt-4" routerLink="/">Retour</div>
  <div class="login-box">
    <div class="text-center">
      <div *ngIf="success == true" class="text-center">
        <h1 class="title mb-2 mb-sm-4 text-uppercase">E-mail envoyé</h1>
      </div>
      <div *ngIf="success == false" class="text-center">
        <h1 class="title mb-2 mb-sm-4">Recevoir mon e-mail de validation</h1>
      </div>
    </div>
    
    <div *ngIf="success == true" class="text-center">
      <p>L'e-mail de validation a été envoyé</p>

        <a routerLink="/" class="btn btn-secondary arrow return">Retour</a>
    </div>
  
    <form *ngIf="success == false" [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
        <p class="mb-4">Si vous n'avez pas correctement reçu votre e-mail de validation de compte (pensez à regarder aussi dans le dossier spams), saisissez l'e-mail avec lequel vous vous êtes inscrit au club afin que l'e-mail vous soit envoyé à nouveau.</p>
        <div class="form-group mb-4">
            <label for="username">E-mail</label>
            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
            <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                <div *ngIf="f['username'].errors['required']">Ce champ est obligatoire</div>
                <div *ngIf="f['username'].errors['email']">Ce n'est pas un e-mail valide</div>
            </div>
        </div>
        <div class="mb-2 text-center">
          <button [disabled]="loading" class="btn btn-primary">
              Valider
          </button>
        </div>
    </form>
  </div>
</div>
<div class="container">
  <div class="btn btn-secondary arrow return mb-4 mt-4" routerLink="/">Retour</div>
  <div class="login-box">
    <div *ngIf="success === true">
      <div class="text-center">
        <h1 class="title mb-2 mb-sm-4 text-uppercase">NOUS VOUS REMERCIONS POUR VOTRE PRÉINSCRIPTION</h1>
        <p *ngIf="env.app === 'jaguar'">
          Devenir client Jaguar, c'est partager, avec d'autres passionnés, des expériences inédites et exclusives.<br>
          Entre culture, sport et gastronomie : vivez des moments d'exception avec le Jaguar Club.<br><br>
          <b>Nous vous invitons à consulter vos emails (ainsi que vos spams) afin de finaliser dès maintenant votre inscription depuis l’e-mail “Des expériences d'exception avec le Jaguar Club France”.</b> Vous pourrez personnaliser votre profil et accéder à vos privilèges, en lien avec vos centres d’intérêt.
        </p>
        <p *ngIf="env.app === 'landrover'">
          Devenir client Land Rover, c'est partager, avec d'autres passionnés, des expériences inédites et exclusives.<br>
          Entre culture, sport et gastronomie : vivez des moments d'exception avec le Land Rover Club.<br><br>
          <b>Nous vous invitons à consulter vos emails (ainsi que vos spams) afin de finaliser dès maintenant votre inscription depuis l’e-mail “Des expériences d'exception avec le Land Rover Club France”</b>. Vous pourrez personnaliser votre profil et accéder à vos privilèges, en lien avec vos centres d’intérêt.
        </p>

        <a routerLink="/" class="btn btn-primary">Retour</a>
      </div>
    </div>
    <div *ngIf="success === false">
      <div class="text-center">
        <h1 class="title mb-2 mb-sm-4 text-uppercase">Inscription</h1>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
          <div class="form-group mb-2">
              <label for="model">Modèle*</label>
              <select type="text" formControlName="model" class="form-select" [ngClass]="{ 'is-invalid': submitted && f['model'].errors }">
                <option *ngFor="let model of availableModels" [value]="model?.id">{{ model?.name }}</option>
              </select>
              <div *ngIf="submitted && f['model'].errors" class="invalid-feedback">
                  <div *ngIf="f['model'].errors['required']">Vous devez choisir un modèle</div>
              </div>
          </div>
          <div class="form-group mb-2">
              <label for="dealership">Concession*</label>
              <select type="text" formControlName="dealership" class="form-select" [ngClass]="{ 'is-invalid': submitted && f['dealership'].errors }">
                <option *ngFor="let dealership of availableDealerships" [value]="dealership?.id">{{ dealership?.name }}</option>
              </select>
              <div *ngIf="submitted && f['dealership'].errors" class="invalid-feedback">
                  <div *ngIf="f['dealership'].errors['required']">Vous devez choisir un concessionnaire</div>
              </div>
          </div>
          <div class="form-group mb-2">
              <label for="chassis">Numéro de châssis (17 caractères)*</label>
              <input type="text" formControlName="chassis" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['chassis'].errors }" />
              <div *ngIf="submitted && f['chassis'].errors" class="invalid-feedback">
                  <div *ngIf="f['chassis'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['chassis'].errors['bad-chassis-number']">Ce numéro de châssis n'est pas valide</div>
                  <div *ngIf="f['chassis'].errors['already-existing-chassis-number']">Ce numéro de châssis existe déjà. Veuillez nous contacter afin de régler cet incident</div>
              </div>
              <div class="small text-black-50">Le numéro de châssis est renseigné dans le champ E de votre carte grise.</div>
          </div>
          <div class="form-group mb-2">
              <label for="registration">Date de 1ère mise en circulation*</label>
              <input type="date" (change)="changeMinDate()" [max]="getTodaysDate()" formControlName="registration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['registration'].errors }" />
              <div *ngIf="submitted && f['registration'].errors" class="invalid-feedback">
                  <div *ngIf="f['registration'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['registration'].errors['is-future-date']">Cette date ne peut pas être une date future</div>
              </div>
              <div class="small text-black-50">La date de mise en circulation est renseignée dans le champ B de votre carte grise.</div>
          </div>
          <div class="form-group mb-2">
              <label for="delivery">Date de livraison*</label>
              <input type="date" [min]="minDate" [max]="getTodaysDate()" formControlName="delivery" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['delivery'].errors }" />
              <div *ngIf="submitted && f['delivery'].errors" class="invalid-feedback">
                  <div *ngIf="f['delivery'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['delivery'].errors['must-be-superior']">Cette date ne peut pas être antérieure à la date de mise en circulation</div>
                  <div *ngIf="f['delivery'].errors['is-future-date']">Cette date ne peut pas être une date future</div>
              </div>
              <div class="small text-black-50">La date de livraison est renseignée dans le champ I de votre carte grise.</div>
          </div>
          <div class="form-check mb-4">
            <input class="form-check-input" id="cgusCheckbox" type="checkbox" formControlName="isvoa" value="" [ngClass]="{ 'is-invalid': submitted && f['isvoa'].errors }">
            <label class="form-check-label" for="cgusCheckbox">
              Garantie VO Approved
            </label>
            <div class="small text-black-50">La garantie Approved couvre tous les composants de votre véhicule et vous délivre le même niveau de service qu’une garantie sur un véhicule neuf.</div>
          </div>
          <div class="form-group mb-4">
              <label for="email">E-mail*</label>
              <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
              <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                  <div *ngIf="f['email'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['email'].errors['email']">Ce n'est pas un e-mail valide</div>
                  <div *ngIf="f['email'].errors['already-existing-user']">Ce compte existe déjà. Nous vous invitons à réinitialiser votre mot de passe et à contacter les équipes Club si nécessaire.</div>
              </div>
          </div>
          <div class="mb-2 text-center">
            <button [disabled]="loading" class="btn btn-primary">
                S'inscrire
            </button>
          </div>
          <div class="form-legend">* Champ obligatoire</div>
      </form>
      
      <div class="fs-6 mt-4">
        <div class="fs-6 mt-4">
        <p *ngIf="env.app === 'jaguar'">VOUS ÊTES PROPRIÉTAIRE D'UNE JAGUAR NEUVE ?<br>
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Jaguar Club pendant {{ appSettings.vnduration }} ans, dès la signature de votre bon de commande.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UNE JAGUAR VO APPROVED&nbsp;?<br>
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Jaguar Club pendant {{ appSettings.voaduration }} ans.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UNE JAGUAR D'OCCASION&nbsp;?<br>
  Vous pouvez adhérer au Jaguar Club durant {{ appSettings.renewduration }} ans renouvelables, moyennant une cotisation de {{ appSettings.cotisation }}€ (soit {{ appSettings.cotisation / appSettings.renewduration }}€ par an).
  </p>
        <p *ngIf="env.app === 'landrover'">VOUS ÊTES PROPRIÉTAIRE D'UN LAND ROVER NEUF ?<br>
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Land Rover Club pendant {{ appSettings.vnduration }} ans, dès la signature de votre bon de commande.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UN LAND ROVER VO APPROVED&nbsp;?<br>
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Land Rover Club pendant {{ appSettings.voaduration }} ans.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UN LAND ROVER D'OCCASION&nbsp;?<br>
  Vous pouvez adhérer au Land Rover Club durant {{ appSettings.renewduration }} ans renouvelables, moyennant une cotisation de {{ appSettings.cotisation }}€ (soit {{ appSettings.cotisation / appSettings.renewduration }}€ par an).
  </p>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { OfferService } from '@services/offer.service';

@Component({ 
	selector: 'passwordfield', 
	templateUrl: 'passwordfield.component.html',
	inputs: ['submitted', 'form', 'formGroup', 'fieldName']
})
export class PasswordFieldComponent implements OnInit {
	
	submitted = false;
	form:any;
	formGroup:any;
	fieldName:any;
	visible = false;
	icon = "mdi-eye-outline";
	
    constructor(
        private offerService: OfferService
    ) {  }

    ngOnInit() {}
    
    toggleVisibility() {
      this.visible = !this.visible;
      if( this.visible === true )
      {
      	this.icon = "mdi-eye-off-outline";
      }
      else
      {
      	this.icon = "mdi-eye-outline";
      }
    }
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SearchService {
    constructor(
        private router: Router,
        private http: HttpClient
    ) {}

    search(q: any): any {
    	return this.http.post(`${environment.apibase}search/`, { q: q });
    }
}
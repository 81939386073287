import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { SettingsService } from '@app/_services/settings.service';
import { EventService } from '@app/_services/event.service';
import { FormatterService } from '@app/_services/formatter.service';

declare let window:any;

@Component({
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {
  formCredentials: FormGroup;
  formDelete: FormGroup;
  formInfos: FormGroup;
  formInterests: FormGroup;
  formCommunications: FormGroup;
  loading = false;
  submitted = false;
  env = environment;
  errors = [];
  userInfos: any = null;
  interests: any = null;
  communications: any = null;
  invoices: any = null;
  appSettings:any;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public accountService: AccountService,
    private alertService: AlertService,
    private settingsService: SettingsService,
    public formatterService: FormatterService,
    public eventService: EventService
  ) {
    this.formCredentials = this.formBuilder.group({
        currentpassword: [''],
        newpassword: [''],
        newpassword2: ['']
    });
    this.formInfos = this.formBuilder.group({
        gender: [''],
        firstname: [''],
        lastname: [''],
        company: [''],
        address: [''],
        addresscpt1: [''],
        addresscpt2: [''],
        postcode: [''],
        city: [''],
        country: [''],
        phone: [''],
        landlinephone: [''],
    });
    this.formDelete = this.formBuilder.group({
        confirm: ['', Validators.required],
    });
    this.formInterests = this.formBuilder.group({});
    this.formCommunications = this.formBuilder.group({
    	newsOptout: ['']
    });
    
    this.settingsService.settings.subscribe(x => { 
      this.appSettings = x;
    });
  }
  
  ngOnInit() {
	this.accountService.getInfos()
	      .subscribe(
	        (data:any) => {
	            this.userInfos = data;
	            
	            this.formInfos = this.formBuilder.group({
	                gender: [this.userInfos.gender],
	                firstname: [this.userInfos.firstname],
	                lastname: [this.userInfos.lastname],
	                company: [this.userInfos.company],
	                address: [this.userInfos.address],
	                addresscpt1: [this.userInfos.addresscpt1],
	                addresscpt2: [this.userInfos.addresscpt2],
	                postcode: [this.userInfos.postcode],
	                city: [this.userInfos.city],
	                country: [this.userInfos.country],
	                phone: [this.userInfos.phone],
	                landlinephone: [this.userInfos.landlinephone],
	            });
	            
	        	this.autoCheckInterests();
	        	this.autoCheckCommunications();
	        },
	        (err:any) => {
	            this.alertService.error('Informations du client inaccessibles');
	        }
	    );
	
	this.settingsService.getInterests()
	      .subscribe(
	        (data:any) => {
	        	let interestsCheckBoxes:any = {};
	        	for( let i in data )
	        	{
	        		interestsCheckBoxes['interests_'+ data[i].id] = false;
	        	}
	        	
	            this.formInterests = this.formBuilder.group(interestsCheckBoxes);
	        	this.interests = data;
	        	
	        	this.autoCheckInterests();
	        },
	        (err:any) => {
	            this.alertService.error('Centres d\'intérêt inaccessibles');
	        }
	    );
	
	
	this.settingsService.getCommunications()
	      .subscribe(
	        (data:any) => {
	        	let communicationsCheckBoxes:any = {};
	        	for( let i in data )
	        	{
	        		communicationsCheckBoxes['communications_'+ data[i].id] = false;
	        	}
	        	
	        	// Acceptation des news
	        	communicationsCheckBoxes['newsOptout'] = false;
	        	
	          this.formCommunications = this.formBuilder.group(communicationsCheckBoxes);
	        	this.communications = data;
	        	
	        	this.autoCheckCommunications();
	        },
	        (err:any) => {
	            this.alertService.error('Communications inaccessibles');
	        }
	    );
	
	this.accountService.getInvoices()
	      .subscribe(
	        (data:any) => {
	        	this.invoices = data;
	        },
	        (err:any) => {
	            this.alertService.error('Factures inaccessibles');
	        }
	    );
  }

  //convenience getter for easy access to form fields
  get fc() { return this.formCredentials.controls; }
  get fi() { return this.formInfos.controls; }
  get fint() { return this.formInterests.controls; }
  get fcom() { return this.formCommunications.controls; }
  get fd() { return this.formDelete.controls; }
  
  autoCheckInterests() {
	  if( this.interests && this.userInfos ) {
		  for( let i in this.fint ) {
			  let id =  i.split('_')[1];
			  for( let j in this.userInfos.interests ) {
				  if( this.userInfos.interests[ j ].id == id ) {
					this.fint[i].setValue(true);
				  }
			  }
		  }
	  }
  }
  
  autoCheckCommunications() {
	  if( this.communications && this.userInfos ) {
		  for( let i in this.fcom ) {
			  let id =  i.split('_')[1];
			  for( let j in this.userInfos.communications ) {
				  if( this.userInfos.communications[ j ].id == id ) {
					  this.fcom[i].setValue(true);
				  }
			  }
		  }
		  
		  if( this.userInfos.recieveNews === true )
		  {
		  	this.fcom['newsOptout'].setValue( false );
		  }
		  else
		  {
		  	this.fcom['newsOptout'].setValue( true );
		  }
	  }
  }
  
  onSubmitCredentials() {
      this.submitted = true;
      
      this.checkPassword();
      
      // stop here if form is invalid
      if (this.formCredentials.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.savePassword( this.formCredentials.value )
        .pipe(first())
        .subscribe(
          data => {
        	  this.alertService.success('Mot de passe changé');
        	  
        	  // Reset des champs
        	  this.fc['currentpassword'].setValue('');
        	  this.fc['newpassword'].setValue('');
        	  this.fc['newpassword2'].setValue('');
        	  
              this.loading = false;
          },
          err => {
              this.alertService.error('Une erreur est survenue');
              console.log( err );
              
              // hightlight des erreurs
              for( let i in err.errors )
              {
                let error = {};
                Object.defineProperty( error, err.errors[i].message, {
                	  value: true,
                	  writable: false
                } );
                
                this.fc[err.errors[i].property].setErrors( error );
              }
              
              this.loading = false;
          }
        );
  }
  
  onSubmitDelete() {
      this.submitted = true;
      
      // stop here if form is invalid
      if (this.formDelete.invalid) {
          return;
      }

      this.loading = true;
      
      this.accountService.deleteAccount()
        .pipe(first())
        .subscribe(
          data => {
        	  this.alertService.success('Votre compte a été supprimé');
        	  	this.accountService.logout();
              this.loading = false;
          },
          err => {
              this.alertService.error('Une erreur est survenue');              
              this.loading = false;
          }
        );
  }
  
  getIconClass( slug:any ) {
	  let map:any = {
		culture: "mdi-temple-hindu",
		gastronomy: "mdi-food-variant",
		wine: "mdi-glass-wine",
		music: "mdi-music",
		travel: "mdi-airplane",
		autosport: "mdi-car-sports",
		tennis: "mdi-tennis-ball",
		golf: "mdi-golf",
		horseriding: "mdi-horse-human",
		rugby: "mdi-rugby",
		email: "mdi-email-outline",
		letter: "mdi-mailbox",
		phone: "mdi-phone",
		push: "mdi-bell",
		sms: "mdi-message-alert",
	  }
	  
	  return map[ slug ];
  }
  
  onSubmitInterests() {
      this.submitted = true;
      
      // stop here if form is invalid
      if (this.formInterests.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.saveInterests( this.formInterests.value )
        .subscribe(
          data => {
            this.alertService.success('Vos centres d\'intéret ont bien été enregistrés');
            
            this.loading = false;
          },
          err => {
              this.alertService.error('Une erreur est survenue');
              this.loading = false;
          }
     );
	  
  }
  
  onSubmitCommunications() {
      this.submitted = true;
      
      // stop here if form is invalid
      if (this.formCommunications.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.saveCommunications( this.formCommunications.value )
        .subscribe(
          data => {
            this.alertService.success('Vos centres d\'intéret ont bien été enregistrés');
            
            this.loading = false;
          },
          err => {
              this.alertService.error('Une erreur est survenue');
              this.loading = false;
          }
     );
	  
  }
  
  onSubmitInfos() {
      this.submitted = true;
      
      // stop here if form is invalid
      if (this.formInfos.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.save( this.formInfos.value )
        .pipe(first())
        .subscribe(
          data => {
            this.alertService.success('Vos informations ont bien été enregistrées');
            
            // Mise à jour du token pour change les élément de l'interfaces lus depuis le token d'accès
            let token = this.accountService.tokens;
            token.gaveTheirInfos = true;
            token.lastname = this.fi['lastname'].value;
            token.firstname = this.fi['firstname'].value;
            this.accountService.saveToken( token );
            
            this.loading = false;
          },
          err => {
              this.alertService.error('Une erreur est survenue');
              console.log( err );
              
              // hightlight des erreurs
              for( let i in err.errors )
              {
                let error = {};
                Object.defineProperty( error, err.errors[i].message, {
                	  value: true,
                	  writable: false
                } );
                
                this.fi[err.errors[i].property].setErrors( error );
              }
              
              this.loading = false;
          }
        );
  }
  
  checkPassword() {
	return;
	let areDifferent = this.fc['password'].value !== this.fc['password2'].value;
	let submitted = this.submitted;
	let secondIsNull = this.fc['password2'].value === '';
	
    if( 
      (areDifferent && submitted)
      || (areDifferent && !submitted && !secondIsNull)
    ) {
      this.fc['password2'].setErrors({'not-the-same': true});
    }
    else
    {
      this.fc['password2'].setErrors(null);
    }
  }
  
  getInvoice(id: any) {
  	let filename = 'facture_' + id;
  	
    this.eventService.getInvoice(id).subscribe((data: any) => {
    	if( window.cordova ) {
    		let folder = window.cordova.file.externalRootDirectory + 'Download'
			  window.resolveLocalFileSystemURL(
			    folder,
			    (dirEntry:any) => {
			      this.createFile(dirEntry, filename + '.pdf', data);
			    }
			  );
    	} else {
	    	let a = document.createElement("a");
	      document.body.appendChild(a);
	      let url= window.URL.createObjectURL(data);
	      a.href = url;
	      a.download = filename;
	      a.click();
	      window.URL.revokeObjectURL(url);
    	}
    });
  }
  
  createFile(dirEntry:any, filename:any, blob:any) {
  	console.log(dirEntry.name, filename);
    // Creates a new file
    dirEntry.getFile(
      filename,
      { create: true, exclusive: false },
      (fileEntry:any) => {
        this.writeFile(fileEntry, blob)
      }
    )
  }
    
  writeFile(fileEntry:any, dataObj:any) {
    // Create a FileWriter object for our FileEntry
    fileEntry.createWriter((fileWriter:any) => {
      fileWriter.onwriteend = () => {
        console.log('Successful file write...')
        console.log( fileEntry.fullPath );
        
        window.cordova.plugins.fileOpener2.open(
        		window.cordova.file.externalRootDirectory + fileEntry.fullPath,
            'application/pdf',
            {
                error : function(e:any) {
                    console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                },
                success : function () {
                    console.log('file opened successfully');
                }
            }
        );
        
      }

      fileWriter.onerror = function(error:any) {
        console.log('Failed file write: ' + error)
      }
      fileWriter.write(dataObj)
    })
  }
  
  readFile(fileEntry:any) {

	    fileEntry.file(function (file:any) {
	        var reader = new FileReader();
	
	        reader.onloadend = function() {
	            console.log( fileEntry.fullPath );
	        };
	
	        reader.readAsText(file);
	
	    });
	}
}

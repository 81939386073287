import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { SettingsService } from '@app/_services/settings.service';

@Component({
  templateUrl: './registerdealership.component.html',
})
export class RegisterDealershipComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  env = environment;
  availableModels = <any>[];
  availableDealerships = <any>[];
  success = false;
  appSettings:any = null;
  tokens:any;
  minDate = "1970-01-01";
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private settingsService: SettingsService
  ) {
    this.form = this.formBuilder.group({
        gender: [''],
        dealerships: [''],
        firstname: [''],
        lastname: [''],
        service: [''],
        email: ['', [/*Validators.required, Validators.email*/]],
        password: ['', [/*Validators.required*/]],
        password2: [''],
    });
    
    this.settingsService.settings.subscribe(x => { 
      this.appSettings = x;
    });
    
    this.tokens = this.accountService.tokens;
  }
  
  ngOnInit() {
  	this.settingsService.getDealerships()
	    .pipe(first())
	    .subscribe(
	      (data:any) => {
	          this.availableDealerships = [...data.data];
	      },
	      (err:any) => {
	          this.alertService.error('Liste des concessions inaccessible');
	      }
	  );
  }

  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
      this.submitted = true;
      
      // reset alerts on submit
      this.alertService.clear();
      
      // Check validity
      
      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.registerDealership( this.form.value )
        .pipe(first())
        .subscribe(
          data => {
            this.success = true;
          },
          err => {
              this.alertService.error('Une erreur est survenue');
              
              // hightlight des erreurs
              for( let i in err.errors )
              {
                let error = {};
                Object.defineProperty( error, err.errors[i].message, {
                	  value: true,
                	  writable: false
                } );
                
                this.f[err.errors[i].property].setErrors( error );
              }
              
              this.loading = false;
          }
        );
  }
  
  changeMinDate() {
  	this.minDate = this.f['registration'].value;
  	this.f['delivery'].setValue('');
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

import { Offer } from '@model/offer';
import { OfferService } from '../_services/offer.service';
import { LoadingService } from '../_services/loading.service';

@Component({
  selector: 'app-offer-single',
  templateUrl: './offer-single.component.html',
  //styleUrls: ['./offer-list.component.scss']
})
export class OfferSingleComponent implements OnInit {

  env = environment;
  public offer : Offer = new Offer;

  constructor( private http: HttpClient, private offerService: OfferService, private route: ActivatedRoute, private loader: LoadingService ) {}

  ngOnInit() {
    let selectedId = parseInt(this.route.snapshot.paramMap.get('id')!);
    this.getOfferSingle(selectedId);
  }
  
  getOfferSingle(selectedId: any) {
    this.offerService.single(selectedId).subscribe((data: any) => { this.offer = data });
  }
  
  getBackgroundThumbnailStyle( offer: Offer ) {
    return `background-image: url(${this.env.filebase}offers/${offer.picture});`;
  }
  
  getPictureUrl( offer: Offer ) {
    return `${this.env.filebase}offers/${offer.picture}`;
  }
}

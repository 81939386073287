import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './account/login.component';
import { LostPasswordComponent } from './account/lostpassword.component';
import { LostValidationLinkComponent } from './account/lostvalidationlink.component';
import { ResetPasswordComponent } from './account/resetpassword.component';
import { ValidationComponent } from './account/validation.component';
import { RegisterComponent } from './account/register.component';
import { RegisterDealershipComponent } from './account/registerdealership.component';
import { SettingsComponent } from './account/settings.component';
import { MyCarsComponent } from './account/mycars.component';
import { AddCarComponent } from './account/addcar.component';
import { SetChassisComponent } from './account/setchassis.component';
import { RenewalComponent } from './account/renewal.component';
import { CguComponent } from './account/cgu.component';
import { HomeComponent } from './home/home.component';
import { OfferListComponent } from './offer/offer-list.component';
import { OfferSingleComponent } from './offer/offer-single.component';
import { ActuListComponent } from './actu/actu-list.component';
import { ActuSingleComponent } from './actu/actu-single.component';
import { EventListComponent } from './event/event-list.component';
import { MyEventListComponent } from './event/my-event-list.component';
import { EventSingleComponent } from './event/event-single.component';
import { EventSubscribeComponent } from './event/event-subscribe.component';
import { DelayedPaymentComponent } from './event/delayed-payment.component';
import { AuthGuard } from '@app/_helpers/auth.guard';
import { AuthAccountGuard } from '@app/_helpers/authaccount.guard';
import { PageComponent } from './page/page.component';
import { SendReviewComponent } from './review/send-review.component';
import { ReviewListComponent } from './review/review-list.component';

const routes: Routes = [
  { path: '', component: EventListComponent, canActivate: [AuthGuard] },
  { path: 'offers', component: OfferListComponent, canActivate: [AuthGuard], data: { title: 'Liste des offres' }},
  { path: 'offer/:id', component: OfferSingleComponent, canActivate: [AuthGuard], data: { title: 'Offre individuelle' } },
  { path: 'actus', component: ActuListComponent, canActivate: [AuthGuard], data: { title: 'Liste des actualités' }},
  { path: 'actu/:id', component: ActuSingleComponent, canActivate: [AuthGuard], data: { title: 'Actualité individuelle' }},
  { path: 'my-account/infos', component: SettingsComponent, canActivate: [AuthGuard], data: { title: 'Compte' } },
  { path: 'my-account/renew', component: RenewalComponent, canActivate: [AuthGuard], data: { title: 'Renouvellement' } },
  { path: 'events', component: EventListComponent, canActivate: [AuthGuard], data: { title: 'Liste des événements' }},
  { path: 'events/:family', component: EventListComponent, canActivate: [AuthGuard], data: { title: 'Liste des événements famille' }},
  { path: 'my-events', component: MyEventListComponent, canActivate: [AuthGuard], data: { title: 'Mes événements' }},
  { path: 'reviews', component: ReviewListComponent, canActivate: [AuthGuard], data: { title: 'Témoignages' }},
  { path: 'my-cars', component: MyCarsComponent, canActivate: [AuthGuard], data: { title: 'Mes véhicules' }},
  { path: 'add-car', component: AddCarComponent, canActivate: [AuthGuard], data: { title: 'Ajouter un véhicule' }},
  { path: 'set-chassis/:id', component: SetChassisComponent, canActivate: [AuthGuard]},
  { path: 'event/:id', component: EventSingleComponent, canActivate: [AuthGuard], data: { title: 'Événement individuel' }},
  { path: 'event/subscribe/:id', component: EventSubscribeComponent, canActivate: [AuthGuard], data: { title: 'Inscription à un événement' }},
  { path: 'event/pay/:hash', component: DelayedPaymentComponent, canActivate: [AuthGuard], data: { title: 'Règlement de l\'événement' }},
  { path: 'send-review/:id', component: SendReviewComponent, canActivate: [AuthGuard], data: { title: 'Je donne mon avis' }},
  { path: 'login', component: LoginComponent, canActivate: [AuthAccountGuard], data: { title: 'Connexion' }},
  { path: 'register', component: RegisterComponent, canActivate: [AuthAccountGuard], data: { title: 'Inscription' }},
  { path: 'register-dealership', component: RegisterDealershipComponent, canActivate: [AuthAccountGuard], data: { title: 'Inscription concessionnaire' } },
  { path: 'lost-password', component: LostPasswordComponent, canActivate: [AuthAccountGuard], data: { title: 'Mot de passe perdu' } },
  { path: 'ask-validation-link', component: LostValidationLinkComponent, canActivate: [AuthAccountGuard], data: { title: 'Demande lien de validation' } },
  { path: 'validate-email/:hash', component: ValidationComponent, canActivate: [AuthAccountGuard], data: { title: 'Inscription étape 2' } },
  { path: 'reset-password/:hash', component: ResetPasswordComponent, canActivate: [AuthAccountGuard], data: { title: 'Redéfinition de mot de passe' } },
  { path: 'page/:slug', component: PageComponent, data: { title: 'Page' } },
  { path: 'new-cgus', component: CguComponent, canActivate: [AuthGuard], data: { title: 'Nouvelles CGUs' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="container">
  <div class="btn btn-secondary arrow return mb-4 mt-4" routerLink="/">Retour</div>
  <div class="login-box">
    <div *ngIf="success === true">
      <div class="text-center">
        <h1 *ngIf="env.app === 'jaguar'" class="title mb-2 mb-sm-4 text-uppercase">BIENVENUE DANS L'UNIVERS DU JAGUAR CLUB</h1>
        <h1 *ngIf="env.app === 'landrover'" class="title mb-2 mb-sm-4 text-uppercase">BIENVENUE DANS L'UNIVERS DU LAND ROVER CLUB</h1>
        <p>
          Votre demande d'accès a bien été transmise au service marketing de JLR pour approbation.
        </p>
        <p>
          Vous recevrez un e-mail dès validation de votre demande de compte.<br />
          À noter : merci de vérifier la boîte des indésirables (spam) si nécessaire. Pour toute question, contactez directement le service marketing JLR.
        </p>
        <a routerLink="/" class="btn btn-primary">Retour</a>
      </div>
    </div>
    <div *ngIf="success === false">
      <div class="text-center">
        <h1 class="title mb-2 mb-sm-4 text-uppercase">Inscription concessionnaire</h1>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
          <div class="form-group mb-2">
            <label for="choice">Civilité*</label>
            <select formControlName="gender"class="form-select" [ngClass]="{ 'is-invalid': submitted && f['gender'].errors }">
              <option value="1">Monsieur</option>
              <option value="2">Madame</option>
            </select>
            <div *ngIf="submitted && f['gender'].errors" class="invalid-feedback">
              <div *ngIf="f['gender'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group mb-2">
                <label for="firstname">Prénom*</label>
                <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }" />
                <div *ngIf="submitted && f['firstname'].errors" class="invalid-feedback">
                  <div *ngIf="f['firstname'].errors['required']">Ce champ est obligatoire</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group mb-2">
                <label for="lastname">Nom*</label>
                <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }" />
                <div *ngIf="submitted && f['lastname'].errors" class="invalid-feedback">
                  <div *ngIf="f['lastname'].errors['required']">Ce champ est obligatoire</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-2">
              <label for="dealerships">Concessions*</label>
              <select multiple="true" type="text" formControlName="dealerships" class="form-select" [ngClass]="{ 'is-invalid': submitted && f['dealerships'].errors }">
                <option *ngFor="let dealership of availableDealerships" [value]="dealership?.id">{{ dealership?.name }}</option>
              </select>
              <div *ngIf="submitted && f['dealerships'].errors" class="invalid-feedback">
                  <div *ngIf="f['dealerships'].errors['required']">Vous devez choisir un concessionnaire</div>
              </div>
          </div>
          <div class="form-group mb-2">
            <label for="choice">Service*</label>
            <select formControlName="service"class="form-select" [ngClass]="{ 'is-invalid': submitted && f['service'].errors }">
              <option value="Vente">Vente</option>
              <option value="Marketing">Marketing</option>
              <option value="Direction">Direction</option>
              <option value="APV">APV</option>
            </select>
            <div *ngIf="submitted && f['service'].errors" class="invalid-feedback">
              <div *ngIf="f['service'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
          <div class="form-group mb-4">
              <label for="email">E-mail*</label>
              <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
              <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                  <div *ngIf="f['email'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['email'].errors['email']">Ce n'est pas un e-mail valide</div>
                  <div *ngIf="f['email'].errors['already-existing-user']">Ce compte existe déjà</div>
              </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group mb-4">
                <label for="password">Mot de passe*</label>
                <passwordfield [formGroup]="form" [form]="f" fieldName="password" [submitted]="submitted"></passwordfield>
                <div *ngIf="submitted && f['password'].errors" class="text-danger">
                  <div *ngIf="f['password'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['password'].errors['bad-pattern']">Le mot de passe ne respecte pas le format obligatoire</div>
                </div>
                <div class="small text-black-50">6 caractères minimum, incluant au moins une minuscule, une majuscule, un chiffre et un caractère spécial (@$!%*?&)</div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group mb-2">
                <label for="password2">Répéter le mot de passe *</label>
                <passwordfield [formGroup]="form" [form]="f" fieldName="password2" [submitted]="submitted"></passwordfield>
                <div *ngIf="submitted && f['password2'].errors" class="text-danger">
                  <div *ngIf="f['password2'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['password2'].errors['not-the-same']">Le mot de passe ne correspond pas</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-2 text-center">
            <button [disabled]="loading" class="btn btn-primary">
                S'inscrire
            </button>
          </div>
          <div class="form-legend">* Champ obligatoire</div>
      </form>
  </div>
</div>
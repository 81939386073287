import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { SettingsService } from '@app/_services/settings.service';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  env = environment;
  appSettings: any = null;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private settingsService: SettingsService
  ) {
    this.form = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
    });
  }
  
  ngOnInit() {
  	this.settingsService.settings.subscribe(x => { 
      this.appSettings = x;
    });
  }

  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
      this.submitted = true;
      
      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.login(this.f['username'].value, this.f['password'].value)
          .pipe(first())
          .subscribe(
              data => {
                  this.router.navigate(['']);
              },
              error => {
                  let message = 'E-mail ou mot de passe invalide';
                  if( error.code == 401 && error.message === 'not-validated' )
                  {
                    message = 'Vous devez valider votre compte à l\'aide de l\'e-mail qui vient de vous être envoyé. Si vous n\'avez pas reçu l\'e-mail, nous vous invitons à cliquer sur le lien "Je n\'ai pas reçu mon lien d\'activation"';
                  }
                  this.alertService.error(message);
                  this.loading = false;
              });
  }
  
  generateBgImage( url:any ) {
    return `background-image: url(${url});`;
  }
}

<div class="filter-box">
  <div class="" id="">
    <form [formGroup]="form">
      <div class="row">
        <div *ngFor="let filter of filters; let i = index" [class]="getFilterClassName( i )">
            <label for="">{{ filter.label }}</label>
            <select (change)="onSubmit()" [formControlName]="filter.name" id="" class="form-control">
              <option value="">Tous/toutes</option>
              <option *ngFor="let value of filter.values | keyvalue" [value]="value.key">{{ value.value }}</option>
            </select>
        </div>
      </div>
    </form>
  </div>
</div>
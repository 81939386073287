import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FormatterService {  
  constructor ()
  {
  }
  
  formatDate( str: any, withHours: any )
  {    
    let date:any = new Date( str );
    let days_map:any = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    let monthes_map:any = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    let day:any = days_map[date.getDay()];
    let month:any = monthes_map[date.getMonth()];
    let date_nb:any = date.getDate();
    let year:any = date.getFullYear()
    let hour:any = date.getHours();
    if( hour < 10 ) hour = '0' + hour;
    let minutes:any = date.getMinutes();
    if( minutes < 10 ) minutes = '0' + minutes;
    let formated:any = day + ' ' + date_nb + ' ' + month + ' ' + year;
    
    if( withHours ) {
      formated += ' à ' + hour + 'h' + minutes;
    }
    return formated;
  }
  
  formatDateSimple( str: any )
  {    
    let date:any = new Date( str );
    let date_nb:any = date.getDate();
    if( date_nb < 10 ) date_nb = '0' + date_nb;
    let year:any = date.getFullYear()
    let month:any = date.getMonth() + 1; // From 0 to 11
    if( month < 10 ) month = '0' + month;
    let formated:any = date_nb + '/' + month + '/' + year;
    return formated;
  }
}
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

import { Session } from '@model/session';
import { Event } from '@model/event';
import { AccountService } from '@app/_services/account.service';
import { EventService } from '../_services/event.service';
import { LoadingService } from '../_services/loading.service';
import { AlertService } from '../_services/alert.service';
import { FormatterService } from '../_services/formatter.service';

declare let window: any;

@Component({
  selector: 'app-event-single',
  templateUrl: './event-subscribe.component.html',
})
export class EventSubscribeComponent implements OnInit {

  env = environment;
  form: FormGroup;
  formPayment:any = null;
  addedPacks = 0;
  loading = false;
  submitted = false;
  currentStep = 1;
  currentSessionId = 0;
  public session : Session = new Session();
  userInfos: any = null;
  currentAmount = 0;
  reductAmount = 0;

  constructor( private http: HttpClient, public formatterService: FormatterService, private alertService: AlertService, private router: Router, public eventService: EventService, private route: ActivatedRoute, private loader: LoadingService, private formBuilder: FormBuilder, public accountService: AccountService )
  {
    this.form = this.formBuilder.group({
          packs: this.formBuilder.array([]),
          infos: [''],
          acceptance: ['', Validators.required],
    });
  }

  ngOnInit() {
    let selectedId = parseInt(this.route.snapshot.paramMap.get('id')!);
    this.currentSessionId = selectedId;
    this.getSessionSingle(selectedId);
  }
  
  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  addPack( lastname:any = '', firstname:any = '' ) {
	  // Ajoute le nombre de places par pack
	  let maxSeatsByPack = 1;
	  
	  if( this.session.type === 'pack' )
	  {
		 maxSeatsByPack = this.session.maxSeatsByPack;
	  }
	  
	  for( let index = 0; index < maxSeatsByPack; index ++ ) {
		  let newGroup = this.formBuilder.group({
			  lastname: [( index == 0 ) ? lastname : '' , Validators.required],
			  firstname: [( index == 0 ) ? firstname : '' , Validators.required],
		  })
		  this.packs().push( newGroup );
	  }
	  
	  this.addedPacks = this.addedPacks + 1
  }
  
  removePack() {
	// on retire le nombre de places par pack
    let maxSeatsByPack = 1;
  
    if( this.session.type === 'pack' )
    {
	  maxSeatsByPack = this.session.maxSeatsByPack;
    }
    
    for( let index = 0; index < maxSeatsByPack; index ++ ) {
	  this.packs().removeAt(this.addedPacks - 1)
	}
    
    this.addedPacks = this.addedPacks - 1
  }
  
  packs(): FormArray {
	  return this.form.get('packs') as FormArray;
  }
  
  getPack(id:any) {
	  let packs:any = this.f['packs'];
	  return packs.controls[id].controls;
  }
  
  stepFurther() {
  	// passage du step 1 au 2, on calcul le prix résultants
  	if( this.currentStep == 1 )
  	{
  		let totalPrice = 0
  		let nbSeats = this.f['packs'].value.length;
  		if( this.session.type === 'pack' ) {
  			totalPrice = this.session.price * ( nbSeats / this.session.maxSeatsByPack );
  		} else {
  			totalPrice = this.session.price * nbSeats;
  		}
  		
  		this.currentAmount = totalPrice;
  		this.reductAmount = totalPrice; // Quand on rajoute les sièges/packs, on met le prix réduit à la meme valeur que le prix du nombre de place. l'application du bon de réduction se fera plus tard
  		
  		// On valide de nouveau le coupon au cas où on est revenu en arrière pour ajouter ou retirer un participant
  		this.validCoupon();
  	}
	  this.currentStep = this.currentStep + 1;
  }
  
  stepFormer() {
	  this.currentStep = this.currentStep - 1;
  }
  
  onSubmit() {
	this.submitted = true;
	
	if(this.f['packs'].invalid )
	{
		this.stepFormer();
	}

	// stop here if form is invalid
	if (this.form.invalid) {
	   return;
	}
	
	this.loading = true;
    this.eventService.registerToSession( this.currentSessionId, this.form.value )
      .pipe(first())
      .subscribe(
        (data:any) => {
          if( data.paymentInfos )
          {
        	  this.alertService.success('Vous allez être redirigé vers notre prestataire de paiement');
              this.formPayment = data.paymentInfos;
              
              let url = this.mercanetHtmlToUrl(data.paymentInfos.redirectionUrl, data.paymentInfos.redirectionVersion, data.paymentInfos.redirectionData);
              window.setTimeout(() => {
                // On traite le formulaire différemment si on est dans l'app mobile ou sur navigateur
                if( window.cordova ) {
                  let inAppBrowserRef = window.cordova.InAppBrowser.open(url, '_blank', 'location=yes,hidden=no');
                  
                  inAppBrowserRef.addEventListener('exit', () => {
                  	window.document.location.href = "#/";
                  });
                  
                  // On détecte la redirection et on quitte le inAppBrowser
                  inAppBrowserRef.addEventListener('loadstart', (params:any) => {
                  	console.log(params.url)
                  	if (params.url.includes("my-events")) {
                        // On ferme la webview 
                        inAppBrowserRef.close();
                    }
                  });
                } else {
  	            let document: any = window.document;
                  document.redirectForm.submit();
                }
              }, 2000);
          }
          else
          {
	      	  this.alertService.success('Votre inscription a bien été enregistrée. Vous recevrez un e-mail vous informant de sa validation.');
	          this.router.navigate(['/my-events']);
          }
          this.loading = false;
        },
        (err:any) => {
        	
        	if( err.message )
        	{
	            this.alertService.error( err.message );
        	}
        	else
        	{
	            this.alertService.error('Une erreur est survenue');
        	}
            
            // hightlight des erreurs
            for( let i in err.errors )
            {
              let error = {};
              Object.defineProperty( error, err.errors[i].message, {
              	  value: true,
              	  writable: false
              } );
              
              this.f[err.errors[i].property].setErrors( error );
            }
            
            this.loading = false;
        }
      );
	
  }
  
  getSessionSingle(selectedId: any) {
    this.eventService.singleSession(selectedId).subscribe((data: any) => { 
    	this.session = data;
    	
	    this.form = this.formBuilder.group({
	          packs: this.formBuilder.array([]),
	          infos: [''],
	          lastname: ['', Validators.required],
	          firstname: ['', Validators.required],
	          company: [''],
	          address: ['', Validators.required],
	          addresscpt1: [''],
	          addresscpt2: [''],
	          postcode: ['', Validators.required],
	          city: ['', Validators.required],
	          country: ['', Validators.required],
	          acceptance: ['', Validators.required],
	          code: [''],
	    });
    	    
	    this.accountService.getInfos()
      .subscribe(
        (data:any) => {
            this.userInfos = data;
            
            this.f['lastname'].setValue(this.userInfos.lastname);
            this.f['firstname'].setValue(this.userInfos.firstname);
            this.f['company'].setValue(this.userInfos.company);
            this.f['address'].setValue(this.userInfos.address);
            this.f['addresscpt1'].setValue(this.userInfos.addresscpt1);
            this.f['addresscpt2'].setValue(this.userInfos.addresscpt2);
            this.f['postcode'].setValue(this.userInfos.postcode);
            this.f['city'].setValue(this.userInfos.city);
            this.f['country'].setValue(this.userInfos.country);
            
			    	this.addPack( this.userInfos.lastname, this.userInfos.firstname );
        },
        (err:any) => {
            this.alertService.error('Informations du client inaccessibles');
        }
      );
    });
  }
  
  getBackgroundThumbnailStyle( event: Event ) {
    return `background-image: url(${this.env.filebase}events/${event.picture});`;
  }
  
  getPictureUrl( event: Event ) {
    return `${this.env.filebase}events/${event.picture}`;
  }
  
  mercanetHtmlToUrl(url:any, version:any, data:any) {
    let HTML =
      '<form method="post" id="mercanetForm" action="' +
      url +
      '">' +
      '<input type="hidden" name="redirectionVersion" value="' +
      version +
      '">' +
      '<input type="hidden" name="redirectionData" value="' +
      data +
      '">' +
      '</form>' +
      '<script type="text/javascript">document.getElementById("mercanetForm").submit();</script>'

    return 'data:text/html;base64,' + btoa(HTML)
  }
  
  validCoupon() {
  	let code = null;
  	
  	if( this.f['code'] )
  	{
  	  code = this.f['code'].value;
  	}
  		
  	
  	if( code )
  	{
	  	this.accountService.checkCoupon(code, this.currentAmount).subscribe((res: any) => {
	  		if( res.exists )
	  		{
	  			this.reductAmount = res.amount;
	  		}
	  		else
	  		{
	  			this.f['code'].setValue('');
		  		this.alertService.error('Coupon inexistant');
	  		}
	  	}, () => {
	  		this.alertService.error('Erreur de validation du coupon');
	  	});
  	}
  	
  }
  
  changeCoupon() {
  	this.reductAmount = this.currentAmount; // Si on change le code du coupon, on reset le prix
  }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '@environments/environment';

import { FilterService } from '@services/filter.service';

@Component({ 
	selector: 'filter', 
	templateUrl: 'filter.component.html'
})
export class FilterComponent implements OnInit, OnDestroy {
	public env = environment;
	public form: FormGroup;
	public filters: Array<any> = [];
	private filterSubscription: any;
	
    constructor(
      private filterService: FilterService,
      private formBuilder: FormBuilder
    ) {
      this.form = this.formBuilder.group({});
    }

    ngOnInit() {
      this.filterSubscription = this.filterService.getFilters().subscribe((filters) => {
    	// console.log( "FILTER COMPONENT DETECT FILTERS CHANGE", filters );
        if( this.filterService.isNew )
        {
          // console.log( "IS NEW FILTER" );
          this.filterService.isNew = false;
          this.filters = filters;
          
          let form = {};
          for( let i in filters )
          {
            Object.defineProperty(form, filters[i].name, {
              value: ['', Validators.required],
              enumerable: true,
            });
          }
          this.form = this.formBuilder.group( form );
        }
      });
    }
    
    ngOnDestroy() {
      this.filterSubscription.unsubscribe();
    }
    
    getFilterClassName( id:any ) {
      if( this.filters[id].classes ) {
        return this.filters[id].classes;
      }
      
      return '';
    }
    
    //convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    
    onSubmit() {
      let choices = {};
    	
      for( let fieldName in this.f )
      {
        if( this.f[fieldName].value )
        {
          Object.defineProperty(choices, fieldName, {
              value: this.f[fieldName].value,
              enumerable: true,
          });
        }
      }
      
      this.filterService.changeChoices( choices );
    }
}
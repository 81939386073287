<div class="container">
  <div class="login-box">
    <div class="text-center">
      <h1 class="title mb-2 mb-sm-4">Je renseigne un numéro de chassis</h1>
    </div>
  
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
        <div>
          <div class="form-group mb-2">
              <label for="chassis">Mon numéro de chassis</label>
              <input type="text" formControlName="chassis" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['chassis'].errors }" />
              <div *ngIf="submitted && f['chassis'].errors" class="invalid-feedback">
                  <div *ngIf="f['chassis'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['chassis'].errors['bad-chassis-number']">Ce numéro de chassis n'est pas valide</div>
                  <div *ngIf="f['chassis'].errors['already-existing-chassis-number']">Ce numéro de chassis est déjà existant dans la base de donnée. Veuillez nous contacter afin de régler l'incident !</div>
                  <div *ngIf="f['chassis'].errors['not-a-car']">Véhicule inexistant</div>
                  <div *ngIf="f['chassis'].errors['not-the-owner']">Vous n'avez pas le droit de modifier ce véhicule</div>
              </div>
              <div class="small text-black-50">Le numéro de chassis est renseigné dans le champ E de votre carte grise.</div>
          </div>
          <div class="form-group mb-2">
              <label for="registration">Date de mise en circulation</label>
              <input type="date" formControlName="registration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['registration'].errors }" />
              <div *ngIf="submitted && f['registration'].errors" class="invalid-feedback">
                  <div *ngIf="f['registration'].errors['required']">Ce champ est obligatoire</div>
              </div>
              <div class="small text-black-50">La date de mise en circulation est renseignée dans le champ B de votre carte grise.</div>
          </div>
          <div class="form-group mb-2">
              <label for="delivery">Date de mise en circulation</label>
              <input type="date" formControlName="delivery" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['delivery'].errors }" />
              <div *ngIf="submitted && f['delivery'].errors" class="invalid-feedback">
                  <div *ngIf="f['delivery'].errors['required']">Ce champ est obligatoire</div>
              </div>
              <div class="small text-black-50">La date de livraison est renseignée dans le champ I de votre carte grise.</div>
          </div>
        </div>
        <div class="mb-2 text-center">
          <button [disabled]="loading" class="btn btn-primary">
              Enregistrer
          </button>
        </div>
    </form>
  </div>
</div>
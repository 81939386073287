import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { PageService } from '@app/_services/page.service';

@Component({
  templateUrl: './cgu.component.html',
})
export class CguComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  env = environment;
  privacyPage:any = null;
  termsOfSalePage:any = null;
  membershipConditionPage:any = null;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private pageService: PageService
  ) {
    this.form = this.formBuilder.group({
        cgus: ['', Validators.required],
    });
  }
  
  ngOnInit() {
    this.pageService.single('privacy-policy')
      .pipe(first())
      .subscribe(
        (data:any) => {
            this.privacyPage = data;
        },
        (err:any) => {
            this.alertService.error('Page inaccessible');
        }
    );
    
    this.pageService.single('membership-conditions')
      .pipe(first())
      .subscribe(
        (data:any) => {
            this.membershipConditionPage = data;
        },
        (err:any) => {
            this.alertService.error('Page inaccessible');
        }
    );
    
    this.pageService.single('terms-of-sales')
      .pipe(first())
      .subscribe(
        (data:any) => {
            this.termsOfSalePage = data;
        },
        (err:any) => {
            this.alertService.error('Page inaccessible');
        }
    );
  }

  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
      this.submitted = true;
      
      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.acceptCgus()
          .pipe(first())
          .subscribe(
              data => {
            	  let tokens = this.accountService.tokens;
            	  tokens.lastAcceptedCguVersion = data.new_code;
            	  tokens.lastCguVersion = data.new_code;
            	  this.accountService.saveToken(tokens);
                  this.router.navigate(['']);
              },
              error => {
                  let message = 'Erreur de validation';
                  this.alertService.error(message);
                  this.loading = false;
              });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { of } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { Event } from '@model/event';
import { Participation } from '@model/participation';
import { EventService } from '@services/event.service';
import { FilterService } from '@services/filter.service';

import { CarouselComponent } from '@components/carousel.component';

declare let window: any;

@Component({
  selector: 'app-my-event-list',
  templateUrl: './my-event-list.component.html',
  //styleUrls: ['./event-list.component.scss']
})
export class MyEventListComponent implements OnInit, OnDestroy {

  env = environment;
  public participations : Participation[] = []; // Events affichées

  constructor( private http: HttpClient, public eventService: EventService, public filterService: FilterService ) {}

  ngOnInit(): void {
    this.getEventsList();
  }
  
  ngOnDestroy(): void {
  }
  
  getEventsList() {
    this.eventService.listForUser().subscribe((data: any) => {
      // mise à jour de la liste des offres
      let res = data.data;
      this.participations = [...res];
    });
  }
  
  getInvoice(id: any) {
  	let filename = 'facture_' + id;
  	
    this.eventService.getInvoice(id).subscribe((data: any) => {
    	if( window.cordova ) {
    		let folder = window.cordova.file.externalRootDirectory + 'Download'
			  window.resolveLocalFileSystemURL(
			    folder,
			    (dirEntry:any) => {
			      this.createFile(dirEntry, filename + '.pdf', data);
			    }
			  );
    	} else {
	    	let a = document.createElement("a");
	      document.body.appendChild(a);
	      let url= window.URL.createObjectURL(data);
	      a.href = url;
	      a.download = filename;
	      a.click();
	      window.URL.revokeObjectURL(url);
    	}
    });
  }
  
  createFile(dirEntry:any, filename:any, blob:any) {
  	console.log(dirEntry.name, filename);
    // Creates a new file
    dirEntry.getFile(
      filename,
      { create: true, exclusive: false },
      (fileEntry:any) => {
        this.writeFile(fileEntry, blob)
      }
    )
  }
    
  writeFile(fileEntry:any, dataObj:any) {
    // Create a FileWriter object for our FileEntry
    fileEntry.createWriter((fileWriter:any) => {
      fileWriter.onwriteend = () => {
        console.log('Successful file write...')
        console.log( fileEntry.fullPath );
        
        window.cordova.plugins.fileOpener2.open(
        		window.cordova.file.externalRootDirectory + fileEntry.fullPath,
            'application/pdf',
            {
                error : function(e:any) {
                    console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                },
                success : function () {
                    console.log('file opened successfully');
                }
            }
        );
        
      }

      fileWriter.onerror = function(error:any) {
        console.log('Failed file write: ' + error)
      }
      fileWriter.write(dataObj)
    })
  }
  
  readFile(fileEntry:any) {

	    fileEntry.file(function (file:any) {
	        var reader = new FileReader();
	
	        reader.onloadend = function() {
	            console.log( fileEntry.fullPath );
	        };
	
	        reader.readAsText(file);
	
	    });
	}
  
  getBackgroundThumbnailStyle( event: Event|null ) {
    return `background-image: url(${this.env.filebase}events/${event?.picture});`;
  }
  
  isFutureDate( _date:any ){
  	let now = new Date();
  	let date = new Date( _date );
  	
  	if( now.getTime() < date.getTime() ) {
  		return true;
  	}
  		
  	return false;
  }
  
  getThumbnailSrc( event: Event ) {
    return `${this.env.filebase}events/${event.picture}`;
  }
}

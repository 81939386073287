<div *ngIf="appSettings?.families && !selectedFamily" class="mb-5 boxes-families">
  <div *ngFor="let family of appSettings.families" class="box-family" [class]="family.code" [style]="generateBgImage( family.image )">
    <div class="centered-content">
      <h2>{{ family.name }}</h2>
      <a [routerLink]="createFamilyLink(family.code)" class="btn btn-primary mt-4">Entrez</a>
    </div>
  </div>
</div>

<div class="container">
  <div *ngIf="selectedFamily" class="mb-5">
    <div class="box-uniq-family">
      <img [src]="findFamilyByCode( selectedFamily, 'image' )" alt="" />
      <div class="centered-content">
        <h2>{{ findFamilyByCode( selectedFamily, 'name' ) }}</h2>
      </div>
    </div>
  </div>
  <carousel *ngIf="!selectedFamily" [items]="sliderEvents" folder="events" baseurl="event/"></carousel>
  <filter></filter>
  <div *ngIf="events.length > 0; else noResult" class="row">
    <div *ngFor="let event of events" class="col-sm-6 col-md-4" [routerLink]="['/event', event.id]">
      <div class="box">
        <div *ngIf="!selectedFamily && event.family?.code" class="ribbon family" [style]="generateBgImage( findFamilyByCode( event.family?.code, 'image' ) )">{{ event.family?.code }}</div>
        <div class="thumbnail">
          <img [src]="getThumbnailSrc(event)" alt="" />
        </div>
        <div class="content">
          <div class="title h4">{{ event?.title }}</div>
          <div class="row">
            <div class="col-sm-12 mb-2">
              <ng-template #moreSessionsDate>
                <div class="description">
                  Sessions disponibles : {{ event.sessions.length }}<br />
                  À partir du {{ eventService.getFirstSession(event.sessions).date|date:'dd/MM/y' }}
                </div>
              </ng-template>
              <div class="description" *ngIf="event.sessions.length <= 1; else moreSessionsDate">
                <span *ngIf="event.sessions[0].dateEnd == null">Le {{ eventService.getFirstSession(event.sessions).date|date:'dd/MM/y' }}</span>
                <span *ngIf="event.sessions[0].dateEnd != null">Du {{ eventService.getFirstSession(event.sessions).date|date:'dd/MM/y' }} <br>au {{ eventService.getFirstSession(event.sessions).dateEnd|date:'dd/MM/y' }}</span>
              </div>
            </div>
            <div *ngIf="event.isParticipating == false" class="col-sm-12">
              <ng-template #moreSessionsUnitsLeft>
                <div class="btn btn-danger">Complet</div>
              </ng-template>
              <div class="units-left btn btn-success" *ngIf="eventService.countUnitsLeft(event.sessions) > 0; else moreSessionsUnitsLeft">Places restantes : {{ eventService.countUnitsLeft(event.sessions) }}</div>
            </div>
            <div *ngIf="event.isParticipating == true" class="col-sm-12">
              <div *ngIf="event.participatingStatus == 'registered'" class="units-left btn btn-success">Inscrit</div>
              <div *ngIf="event.participatingStatus == 'waiting'" class="units-left btn btn-warning">Sur liste d'attente</div>
              <div *ngIf="event.participatingStatus == 'pending-payment'" class="units-left btn btn-warning">En attente de paiement</div>
              <div *ngIf="event.participatingStatus == 'refused'" class="units-left btn btn-danger">Refusé</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noResult>
    Aucun événement trouvé
  </ng-template>
</div>
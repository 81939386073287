import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { OfferService } from '@services/offer.service';

import { environment } from '@environments/environment';

@Component({ 
	selector: 'carousel', 
	templateUrl: 'carousel.component.html',
	inputs: ['items', 'folder', 'baseurl']
})
export class CarouselComponent implements OnInit {
	
	items: any = [];
	folder: string = '';
	baseurl: string = '';
	env = environment;
	
    constructor(
        private offerService: OfferService
    ) {  }

    ngOnInit() {}
}
<div class="container">
  <carousel [items]="sliderActus" folder="actus" baseurl="actu/"></carousel>
  <form [formGroup]="form" action="" (submit)="submitSearch()">
    <div class="filter-box">
      <div class="row align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label">Recherche: </label>
        </div>
        <div class="col-auto">
          <input class="form-control w-20" placeholder="Mot Clé" formControlName="searchTerm">
        </div>
        <div class="col-auto">
          <button class="btn btn-secondary arrow" type="submit">Rechercher</button>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div *ngFor="let actu of actus" class="col-sm-6 col-md-4" [routerLink]="['/actu', actu.id]">
      <div class="box">
        <div class="thumbnail">
          <img [src]="getThumbnailSrc(actu)" alt="" />
        </div>
        <div class="content">
          <div class="title h4">{{ actu?.title }}</div>
          <div class="date">{{ actu?.pubStart|date:'dd/MM/y' }}</div>
          <div class="description">{{ actu?.summary }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
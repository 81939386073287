import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';

@Component({
  templateUrl: './resetpassword.component.html',
})
export class ResetPasswordComponent implements OnInit {
  env = environment;
  form: FormGroup;
  loading = false;
  submitted = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService
  ) {
    this.form = this.formBuilder.group({
          password: ['', Validators.required],
          password2: ['', Validators.required],
          hash: [ this.route.snapshot.paramMap.get('hash') ]
    });
  }
  
  ngOnInit() {
  }
  
  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
      this.submitted = true;
      
      // reset alerts on submit
      this.alertService.clear();

      //this.checkPassword();

      console.log(this.f['hash'].errors);
      
      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.resetPassword( this.form.value )
          .pipe(first())
          .subscribe(
              data => {
            	  this.alertService.success('Mot de passe modifié');
                  this.router.navigate(['']);
              },
              err => {
            	  let message = 'Une erreur est survenue';
                  if( err.success == false && err.message === 'non-existing-link' )
                  {
                    message = 'Lien de redéfinition de mot de passe invalide';
                  }

            	  this.alertService.error(message);
                  
                  // hightlight des erreurs
                  for( let i in err.errors )
                  {
	            	  
                    let error = {};
                    Object.defineProperty( error, err.errors[i].message, {
                    	  value: true,
                    	  writable: false
                    } );
                    
                    this.f[err.errors[i].property].setErrors( error );
                  }
                  
                  this.loading = false;
              });
  }
  
  checkPassword() {
		let areDifferent = this.f['password'].value !== this.f['password2'].value;
		let submitted = this.submitted;
		let secondIsNull = this.f['password2'].value === '';
	
    if( 
      (areDifferent && submitted)
      || (areDifferent && !submitted && !secondIsNull)
    ) {
      this.f['password2'].setErrors({'not-the-same': true});
    }
    else
    {
      this.f['password2'].setErrors(null);
    }
  }
}

<div class="container">
  <div class="text-center">
      <h1 class="title mb-2 mb-sm-4 text-uppercase">PLUS QU’UNE ÉTAPE POUR<br class="d-none d-sm-block"> ACTIVER VOTRE COMPTE</h1>
  </div>
  
  <div id="video-presentation">
    <div id="video_overlay" [ngClass]="{ 'd-none': videoOverlay === false }" (click)="playVideo()">
      <svg viewBox="0 0 60 60" aria-label="Play the video" version="1.1" id="svg4" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs8"/><g id="layer2"><circle style="fill:#fff;stroke-width:3.42364;paint-order:fill markers stroke;fill-opacity:1" id="path1688" cx="29.955397" cy="29.955397" r="29.66659"/></g><g id="layer1" style="display:inline"><path d="m 25.5,20.5 14,9.5 -14,9.5 z M 60,30 C 60,46.6 46.6,60 30,60 13.4,60 0,46.6 0,30 0,13.4 13.4,0 30,0 46.6,0 60,13.4 60,30 Z m -1,0 C 59,14 46,1 30,1 14,1 1,14 1,30 1,46 14,59 30,59 46,59 59,46 59,30 Z" id="path2"/></g></svg>
    </div>
  </div>
  
  <form [formGroup]="form" (submit)="onSubmit()" class="form-spec">
  
    <div [ngClass]="{'d-none': currentStep !== 1}">
      <h2>Informations personnelles</h2>
      <p *ngIf="env.app == 'jaguar'">Pour activer votre compte Jaguar Club, veuillez remplir le formulaire ci-dessous :</p>
      <p *ngIf="env.app == 'landrover'">Pour activer votre compte Land Rover Club, veuillez remplir le formulaire ci-dessous :</p>
      
      <div class="form-group mb-2">
        <div class="is-invalid"></div>
        <div *ngIf="submitted && f['hash'].errors" class="invalid-feedback">
          <div *ngIf="f['hash'].errors['hash-is-invalid']">Le lien de validation est incorrect</div>
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="choice">Civilité*</label>
        <select formControlName="gender"class="form-select" [ngClass]="{ 'is-invalid': submitted && f['gender'].errors }">
          <option value="1">Monsieur</option>
          <option value="2">Madame</option>
        </select>
        <div *ngIf="submitted && f['gender'].errors" class="invalid-feedback">
          <div *ngIf="f['gender'].errors['required']">Ce champ est obligatoire</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group mb-2">
            <label for="firstname">Prénom*</label>
            <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }" />
            <div *ngIf="submitted && f['firstname'].errors" class="invalid-feedback">
              <div *ngIf="f['firstname'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-2">
            <label for="lastname">Nom*</label>
            <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }" />
            <div *ngIf="submitted && f['lastname'].errors" class="invalid-feedback">
              <div *ngIf="f['lastname'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="company">Raison sociale</label>
        <input type="text" formControlName="company" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['company'].errors }" />
      </div>
      <div class="form-group mb-2">
        <label for="address">Adresse*</label>
        <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['address'].errors }" />
        <div *ngIf="submitted && f['address'].errors" class="invalid-feedback">
          <div *ngIf="f['address'].errors['required']">Ce champ est obligatoire</div>
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="addresscpt1">Complément d'adresse</label>
        <input type="text" formControlName="addresscpt1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['addresscpt1'].errors }" />
      </div>
      <div class="form-group mb-2">
        <label for="addresscpt2">Complément d'adresse 2</label>
        <input type="text" formControlName="addresscpt2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['addresscpt2'].errors }" />
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group mb-2">
            <label for="postcode">Code postal*</label>
            <input type="text" formControlName="postcode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['postcode'].errors }" />
            <div *ngIf="submitted && f['postcode'].errors" class="invalid-feedback">
              <div *ngIf="f['postcode'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-2">
            <label for="city">Ville*</label>
            <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['city'].errors }" />
            <div *ngIf="submitted && f['city'].errors" class="invalid-feedback">
              <div *ngIf="f['city'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="addresscpt2">Pays*</label>
        <select type="text" formControlName="country" class="form-select" [ngClass]="{ 'is-invalid': submitted && f['country'].errors }" >
          <option value="">-- Choisissez un pays --</option>
          <option *ngFor="let country of appSettings.countries let i = index" [selected]="country === f['country'].value">{{ country }}</option>
        </select>
        <div *ngIf="submitted && f['country'].errors" class="invalid-feedback">
          <div *ngIf="f['country'].errors['required']">Vous devez choisir un pays</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group mb-2">
            <label for="phone">Téléphone portable*</label>
            <input type="text" formControlName="phone"class="form-control" [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }" />
            <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback">
              <div *ngIf="f['phone'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-2">
            <label for="landlinephone">Téléphone fixe</label>
            <input type="text" formControlName="landlinephone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['landlinephone'].errors }" />
            <div *ngIf="submitted && f['landlinephone'].errors" class="invalid-feedback">
              <div *ngIf="f['landlinephone'].errors['required']">Ce champ est obligatoire</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group mb-4">
            <label for="password">Mot de passe*</label>
            <passwordfield [formGroup]="form" [form]="f" fieldName="password" [submitted]="submitted"></passwordfield>
            <div *ngIf="submitted && f['password'].errors" class="text-danger">
              <div *ngIf="f['password'].errors['required']">Ce champ est obligatoire</div>
              <div *ngIf="f['password'].errors['bad-pattern']">Le mot de passe ne respecte pas le format obligatoire</div>
            </div>
            <div class="small text-black-50">6 caractères minimum, incluant au moins une minuscule, une majuscule, un chiffre et un caractère spécial (@$!%*?&)</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-2">
            <label for="password2">Répéter le mot de passe *</label>
            <passwordfield [formGroup]="form" [form]="f" fieldName="password2" [submitted]="submitted"></passwordfield>
            <div *ngIf="submitted && f['password2'].errors" class="text-danger">
              <div *ngIf="f['password2'].errors['required']">Ce champ est obligatoire</div>
              <div *ngIf="f['password2'].errors['not-the-same']">Le mot de passe ne correspond pas</div>
            </div>
          </div>
        </div>
      </div>
      <a (click)="stepFurther()" class="btn btn-primary">
          Continuer
      </a>
    </div>
    
    <div *ngIf="currentStep" [ngClass]="{'d-none': currentStep !== 2}">
      <h2 class="mb-4 text-uppercase">Vos centres d'intérêt</h2>
      <p>Que vous soyez féru(e) de culture, de sport, de gastronomie, le Club vous conviera à des évènements pour vivre pleinement vos passions.</p>
      
      <div class="row" *ngIf="interests">
        <div *ngFor="let interest of interests; let i = index" class="col-sm-6 col-md-3 text-center">
          <label class="checkbox-container mb-4">
              <input type="checkbox" [formControlName]="'interests_'+ interest.id">
              <div class="box">
                <i class="mdi" [class]="getIconClass(interest.slug)"></i><br>
                {{ interest.label }}
              </div>
          </label>
        </div>
      </div>
      <a (click)="stepFormer()" class="btn btn-secondary me-2">
          Retour
      </a>
      <a (click)="stepFurther()" class="btn btn-primary">
          Continuer
      </a>
    </div>
    
    <div *ngIf="currentStep" [ngClass]="{'d-none': currentStep !== 3}">
      <h2 class="mb-4 text-uppercase">Dans le cadre de mon adhésion</h2>
      <b class="fs-5">1. Communications Club :</b>
      <p>
      Vous recevrez les invitations du Club par e-mail. Vous pouvez vous désabonner de ces communications à tout moment via le lien présent dans les e-mails adressés ou via le menu « Mes communications » dans votre espace membre. Dans un tel cas, vous êtes susceptible de ne pas bénéficier des services du Club dans le cadre de votre adhésion.    
      </p>
      
      <b class="fs-5" *ngIf="env.app === 'jaguar'">2. Autres communications Jaguar :</b>
      <b class="fs-5" *ngIf="env.app === 'landrover'">2. Autres communications Land Rover :</b>
      <p *ngIf="env.app === 'jaguar'">
      En votre qualité de client de Jaguar, nous vous enverrons en avant-première des informations sur nos actualités, nos produits et nos services pouvant vous intéresser avec les coordonnées renseignées.
      </p>
      <p *ngIf="env.app === 'landrover'">
      En votre qualité de client de Land Rover, nous vous enverrons en avant-première des informations sur nos actualités, nos produits et nos services pouvant vous intéresser avec les coordonnées renseignées.
      </p>
      
      <p>Vous pouvez refuser ces communications en cochant la case ci-dessous :</p>
      
      <div class="form-check mb-4">
        <input class="form-check-input" id="newsCheckbox" type="checkbox" formControlName="newsOptout" value="" [ngClass]="{ 'is-invalid': submitted && f['newsOptout'].errors }">
        <label *ngIf="env.app === 'jaguar'" class="form-check-label" for="newsCheckbox">
          Je ne souhaite pas recevoir les actualités de la marque Jaguar en dehors du cadre de mon abonnement au Club.
        </label>
        <label *ngIf="env.app === 'landrover'" class="form-check-label" for="newsCheckbox">
          Je ne souhaite pas recevoir les actualités de la marque Land Rover en dehors du cadre de mon abonnement au Club.
        </label>
      </div>
      <b class="mt-4 mb-2 fs-5 d-block">3. Conditions générales d’adhésions du Club :</b>
      <div class="form-check mb-4">
        <input class="form-check-input" id="cgusCheckbox" type="checkbox" formControlName="cgus" value="" [ngClass]="{ 'is-invalid': submitted && f['cgus'].errors }">
        <label class="form-check-label" for="cgusCheckbox">
          Je reconnais avoir pris connaissance et accepte les <a routerLink="/page/membership-conditions">Conditions Générales d’Adhésion</a>*
        </label>
        <div *ngIf="submitted && f['cgus'].errors" class="invalid-feedback">
            <div *ngIf="f['cgus'].errors['required']">Vous devez accepter les CGAs</div>
        </div>
      </div>
      <div class="mb-2 mt-2">
        <a (click)="stepFormer()" class="btn btn-secondary me-2">
            Retour
        </a>
        <button [disabled]="loading" class="btn btn-primary">
            Enregistrer
        </button>
      </div>
      
      <div class="fs-6 mt-4">
        <p *ngIf="env.app === 'jaguar'">VOUS ÊTES PROPRIÉTAIRE D'UNE JAGUAR NEUVE ?<br>
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Jaguar Club pendant {{ appSettings.vnduration }} ans, dès la signature de votre bon de commande.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UNE JAGUAR VO APPROVED&nbsp;?<br />
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Jaguar Club pendant {{ appSettings.voaduration }} ans.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UNE JAGUAR D'OCCASION&nbsp;?<br />
  Vous pouvez adhérer au Jaguar Club durant {{ appSettings.renewduration }} ans renouvelables, moyennant une cotisation de {{ appSettings.cotisation }}€ (soit {{ appSettings.cotisation / appSettings.renewduration }}€ par an).
  </p>
        <p *ngIf="env.app === 'landrover'">VOUS ÊTES PROPRIÉTAIRE D'UN LAND ROVER NEUF ?<br>
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Land Rover Club pendant {{ appSettings.vnduration }} ans, dès la signature de votre bon de commande.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UN LAND ROVER VO APPROVED&nbsp;?<br />
  Nous sommes heureux de vous offrir gratuitement l'adhésion au Land Rover Club pendant {{ appSettings.voaduration }} ans.<br><br>
  VOUS ÊTES PROPRIÉTAIRE D'UN LAND ROVER D'OCCASION&nbsp;?<br />
  Vous pouvez adhérer au Land Rover Club durant {{ appSettings.renewduration }} ans renouvelables, moyennant une cotisation de {{ appSettings.cotisation }}€ (soit {{ appSettings.cotisation / appSettings.renewduration }}€ par an).
  </p>
      </div>
    </div>
  </form>
</div>
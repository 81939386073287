<div class="container">
  <h1 class="title">Mes véhicules</h1>
  <div class="btn btn-primary mb-2" routerLink="/add-car">J'ajoute un véhicule</div>
  <div class="row">
    <div *ngFor="let car of cars" class="col-sm-6 col-md-6 col-lg-6 col-xl-3">    
      <div class="card mb-4 car-box">
        <div class="card-body">
          <div class="heading mb-3">
              <h2 class="">{{ car.model.name }}</h2>
              <div class="actions">
                <span *ngIf="car.isSold">
                  (Vendu)
                </span>
                <span *ngIf="!car.isSold">
                  <a class="me-2" (click)="sellCar(car.id)"><i class="mdi mdi-gavel"></i> J'ai vendu ce véhicule</a><br>
                  <a *ngIf="car.isPreordered" [routerLink]="getChassisUrl(car.id)"><i class="mdi mdi-pencil"></i> Je renseigne un numéro de chassis</a>
                </span>
              </div>
          </div>
          <div class="line mb-2">
              <b *ngIf="!car.isPreordered">Numéro de chassis</b>
              <b *ngIf="car.isPreordered">Numéro de commande</b>
              <br>
              {{ car.chassis }}
          </div>
          <div *ngIf="!car.isPreordered" >
            <div class="line mb-2">
                <b>Date de mise en ciculation</b><br>
                <i class="mdi mdi-calendar"></i>&nbsp;{{ car.registration|date:'dd/MM/y' }}
            </div>
            <div class="line mb-2">
                <b>Date de livraison</b><br>
                <i class="mdi mdi-calendar"></i>&nbsp;{{ car.delivery|date:'dd/MM/y' }}
            </div>
          </div>
          <div class="line">
              <b>Concession</b><br>
              {{ car.dealership.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Event } from '@model/event';

export class Session {
    id: number = 0;
    date: string = '';
    dateEnd: string = '';
    address: string = '';
    addresscpt1: string = '';
    postcode: string = '';
    city: string = '';
    type: string = '';
    maxUnitsByPerson: number = 0;
    nbUnitsLeft: number = 0;
    maxSeatsByPack: number = 0;
    price: number = 0;
    event: Event|null = null;
    region: any = '';
    statusForUser: any = '';
    customFormText: any = '';
}
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

import { Session } from '@model/session';
import { Event } from '@model/event';
import { AccountService } from '@app/_services/account.service';
import { EventService } from '../_services/event.service';
import { LoadingService } from '../_services/loading.service';
import { AlertService } from '../_services/alert.service';
import { FormatterService } from '../_services/formatter.service';
import { ReviewService } from '../_services/review.service';

declare let window: any;

@Component({
  selector: 'send-review',
  templateUrl: './send-review.component.html',
})
export class SendReviewComponent implements OnInit {

  env = environment;
  form: FormGroup;
  loading = false;
  submitted = false;
  currentParticipationId = 0;
  public participation:any;
  userInfos: any = null;
  public context = 'add';
  public selectedMark = 0;

  constructor( private http: HttpClient, public formatterService: FormatterService, private alertService: AlertService, private router: Router, public eventService: EventService, private route: ActivatedRoute, private loader: LoadingService, private formBuilder: FormBuilder, public accountService: AccountService, public reviewService: ReviewService )
  {
    this.form = this.formBuilder.group({
          comment: [''],
          rate: [''],
    });
  }

  ngOnInit() {
    let selectedId = parseInt(this.route.snapshot.paramMap.get('id')!);
    this.currentParticipationId = selectedId;
    this.getReviewSingle(selectedId);
  }
  
  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
		this.submitted = true;
	
		// stop here if form is invalid
		if (this.form.invalid) {
		   return;
		}
		
		this.loading = true;
    this.reviewService.sendReviewForParticipation( this.currentParticipationId, this.form.value )
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.loading = false;
          this.alertService.success( data.message );
          this.router.navigate(['/my-events']);
        },
        (err:any) => {
        	
        	if( err.message )
        	{
	            this.alertService.error( err.message );
        	}
        	else
        	{
	            this.alertService.error('Une erreur est survenue');
        	}
            
            // hightlight des erreurs
            for( let i in err.errors )
            {
              let error = {};
              Object.defineProperty( error, err.errors[i].message, {
              	  value: true,
              	  writable: false
              } );
              
              this.f[err.errors[i].property].setErrors( error );
            }
            
            this.loading = false;
        }
      );
	
  }
  
  getReviewSingle(selectedId: any) {
    this.reviewService.single(selectedId).subscribe((data: any) => { 
    	this.participation = data;
    	this.context = data.context;
    }, (err:any) => {
    	this.alertService.error( err.title );
    });
  }
  
  changeMark(rate:any)
  {
  	this.f['rate'].setValue(rate);
  	this.selectedMark = rate;
  }
}

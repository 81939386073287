import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        
        // exclude connection url & refresh
        if( request.url.includes('login_check') 
            || request.url.includes('token/renew')
            || request.url.includes('register')
            || request.url.includes('settings')
            || request.url.includes('email-validation')
            || request.url.includes('page/')
            || request.url.includes('account/request-lostpassword')
            || request.url.includes('account/request-lostvalidationlink')
            || request.url.includes('account/save-all')
            || request.url.includes('reset-password')
            || request.url.includes('step-2-get-infos')
        )
        {
          return next.handle(request);
        }
        
        let tokens = JSON.parse(localStorage.getItem('user') || '{"token":"xxx"}');
        
        //console.log( request );
        
        request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${tokens.token}`
            }
        });

        return next.handle(request);
    }
}
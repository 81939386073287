<div class="container">
  <carousel [items]="sliderOffers" folder="offers" baseurl="offer/"></carousel>
  <filter></filter>
  <div class="row">
    <div *ngFor="let offer of offers" class="col-sm-6 col-md-4" [routerLink]="['/offer', offer.id]">
      <div class="box">
        <div class="thumbnail">
          <img [src]="getThumbnailSrc(offer)" alt="" />
        </div>
        <div class="content">
          <div class="title h4">{{ offer?.title }}</div>
          <div class="description">Jusqu'au {{ offer?.end|date:'dd/MM/y' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    private settingsSubject: BehaviorSubject<any>;
    public settings: Observable<any>;
    private familiesSubject: BehaviorSubject<any>;
    public families: Observable<any>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.settingsSubject = new BehaviorSubject<any>(null);
        this.settings = this.settingsSubject.asObservable();
        this.familiesSubject = new BehaviorSubject<any>(null);
        this.families = this.familiesSubject.asObservable();
    }
    
    getCarModels(): any {
    	return this.http.get<any[]>(`${environment.apibase}settings/models`);
    }
    
    getDealerships(): any {
    	return this.http.get<any[]>(`${environment.apibase}settings/dealerships`);
    }
    
    getInterests(): any {
    	return this.http.get<any[]>(`${environment.apibase}settings/interests`);
    }
    
    getCommunications(): any {
    	return this.http.get<any[]>(`${environment.apibase}settings/communications`);
    }
    
    getAppSettings(): any {
    	return this.http.get<any>(`${environment.apibase}settings/get-all`, {})
          .pipe(map(settings => {
              /*this.saveToken(user);*/
              this.settingsSubject.next(settings);
          }));
    }
}
<div id="carouselExampleIndicators" class="carousel slide mb-5 d-none d-sm-block" data-bs-ride="true">
  <div class="carousel-indicators">
    <button *ngFor="let item of items; first as isFirst; let i = index;" type="button" data-bs-target="#carouselExampleIndicators" [attr.data-bs-slide-to]="i" [class]="{'active': isFirst}" aria-current="true" aria-label=""></button>
  </div>
  <div class="carousel-inner">
    <div *ngFor="let item of items; first as isFirst" [class]="{'carousel-item':true, 'active': isFirst}">
      <img [src]="env.filebase + folder + '/' +item.picture" class="d-block w-100" alt="...">
      
      <div class="box-content">
        <div class="box-title">
          <h2 class="h4">{{ item.title }}</h2>
          <div [routerLink]="'/' + baseurl + item.id" class="btn btn-primary arrow">En savoir plus</div>
        </div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';
import { filter } from 'rxjs/operators';

import { Event } from '@model/event';
import { EventService } from '../_services/event.service';
import { LoadingService } from '../_services/loading.service';
import { FormatterService } from '../_services/formatter.service';

@Component({
  selector: 'app-event-single',
  templateUrl: './event-single.component.html',
  //styleUrls: ['./offer-list.component.scss']
})
export class EventSingleComponent implements OnInit {

  env = environment;
  public event : Event = new Event;
  private routerSubscription:any = null;

  constructor( private http: HttpClient, public formatterService: FormatterService, public eventService: EventService, private route: ActivatedRoute, private loader: LoadingService, private router: Router ) {}

  ngOnInit() {
    let selectedId = parseInt(this.route.snapshot.paramMap.get('id')!);
    this.getEventSingle(selectedId);
    
    this.routerSubscription = this.router.events.pipe(filter( (event:any) =>  event instanceof NavigationEnd === true  )).subscribe((event: RouterEvent) => {
			this.getEventSingle( this.route.snapshot.paramMap.get('id') );
	  });
  }
  
  ngOnDestroy(): void {
	  this.routerSubscription.unsubscribe();
  }
  
  getEventSingle(selectedId: any) {
    this.eventService.single(selectedId).subscribe((data: any) => { this.event = data; });
  }
  
  getBackgroundThumbnailStyle( event: Event ) {
    return `background-image: url(${this.env.filebase}events/${event.picture});`;
  }
  
  getPictureUrl( event: Event ) {
    return `${this.env.filebase}events/${event.picture}`;
  }
}

export class Event {
    id: number = 0;
    title: string = '';
    description: string = '';
    end: string = '';
    date: string = '';
    picture: string = '';
    categories: [] = [];
    sessions: Array<any> = [];
    family: any = {};
    isParticipating: any = false;
    participatingStatus: any = '';
}
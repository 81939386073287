<div class="container">
  <h1 class="title">Mon compte</h1>
  
  <div class="accordion" id="accordionAccount">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingCredentials">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCredentials" aria-expanded="false" aria-controls="collapseOne">
          Mes identifiants
        </button>
      </h2>
      <div id="collapseCredentials" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionAccount">
        <div class="accordion-body">
          <form [formGroup]="formCredentials" (ngSubmit)="onSubmitCredentials()">
            <div class="form-group mb-2">
              <label for="currentpassword">Mon e-mail</label>
              <input type="text" disabled="disabled" class="form-control" [value]="accountService.tokens.email" />
            </div>
            <div class="form-group mb-2">
              <label for="currentpassword">Ancien mot de passe*</label>
              <input type="password" formControlName="currentpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && fc['currentpassword'].errors }" />
              <div *ngIf="submitted && fc['currentpassword'].errors" class="invalid-feedback">
                <div *ngIf="fc['currentpassword'].errors['required']">Ce champ est obligatoire</div>
                <div *ngIf="fc['currentpassword'].errors['bad-password']">Mot de passe incorrect</div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="newpassword">Nouveau mot de passe*</label>
              <input type="password" formControlName="newpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && fc['newpassword'].errors }" />
              <div *ngIf="submitted && fc['newpassword'].errors" class="invalid-feedback">
                <div *ngIf="fc['newpassword'].errors['required']">Ce champ est obligatoire</div>
                <div *ngIf="fc['newpassword'].errors['bad-pattern']">Le mot de passe ne respecte pas le format obligatoire</div>
              </div>
              <div class="small text-black-50">Doit contenir au moins 6 caractères contenant au moins une minuscule, une majuscule, un chiffre et un caractère spécial (@$!%*?&)</div>
            </div>
            <div class="form-group mb-2">
              <label for="newpassword2">Répéter nouveau mot de passe*</label>
              <input type="password" formControlName="newpassword2" class="form-control" [ngClass]="{ 'is-invalid': submitted && fc['newpassword2'].errors }" />
              <div *ngIf="submitted && fc['newpassword2'].errors" class="invalid-feedback">
                <div *ngIf="fc['newpassword2'].errors['required']">Ce champ est obligatoire</div>
                <div *ngIf="fc['newpassword2'].errors['not-the-same']">Les mots de passe ne sont pas identiques</div>
              </div>
            </div>
            <div class="mb-2 mt-2 text-center">
              <button [disabled]="loading" class="btn btn-primary">
                  Enregistrer
              </button>
            </div>
          </form>
          
          <form class="pt-5" [formGroup]="formDelete" (ngSubmit)="onSubmitDelete()">
            <p>En application du RGPD et de la loi Informatique et Libertés, vous pouvez demander a fermeture de votre compte Club et l’effacement des données qui y sont associées. Dans un tel cas, vous demandez la suppression de vos données personnelles et confirmez qu'aucune des données présentes sur celui-ci ne seront récupérables. La suppression du compte n’entrainera pas la suppression des factures et autres documents comptables relatifs aux achats pour lesquels une obligation légale de conservation existe, et entraînera l’anonymisation des réservations d’événements réalisés durant l’adhésion.</p>
            <div class="form-check mb-4">
              <input class="form-check-input" id="cgusCheckbox" type="checkbox" formControlName="confirm" value="" [ngClass]="{ 'is-invalid': submitted && fd['confirm'].errors }">
              <label class="form-check-label" for="cgusCheckbox">
                Je confirme vouloir supprimer mon compte Club.*
              </label>
              <div *ngIf="fd['confirm'].errors" class="invalid-feedback">
                  <div *ngIf="fd['confirm'].errors['required']">Vous devez cocher cette case pour valider la suppression du compte</div>
              </div>
            </div>
            <div class="mb-2 mt-2 text-center">
              <button [disabled]="loading" class="btn btn-primary">
                  Supprimer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingInfos">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInfos" aria-expanded="true" aria-controls="collapseOne">
          Mes informations
        </button>
      </h2>
      <div id="collapseInfos" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionAccount">
        <div class="accordion-body">
          <form [formGroup]="formInfos" (ngSubmit)="onSubmitInfos()">
            <div class="form-group mb-2">
              <label for="choice">Civilité*</label>
              <select formControlName="gender"class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['gender'].errors }">
                <option value="1">Monsieur</option>
                <option value="2">Madame</option>
              </select>
              <div *ngIf="submitted && fi['gender'].errors" class="invalid-feedback">
                <div *ngIf="fi['gender'].errors['required']">Ce champ est obligatoire</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="firstname">Prénom*</label>
                  <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['firstname'].errors }" />
                  <div *ngIf="submitted && fi['firstname'].errors" class="invalid-feedback">
                    <div *ngIf="fi['firstname'].errors['required']">Ce champ est obligatoire</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="lastname">Nom*</label>
                  <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['lastname'].errors }" />
                  <div *ngIf="submitted && fi['lastname'].errors" class="invalid-feedback">
                    <div *ngIf="fi['lastname'].errors['required']">Ce champ est obligatoire</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="company">Raison sociale</label>
              <input type="text" formControlName="company" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['company'].errors }" />
            </div>
            <div class="form-group mb-2">
              <label for="address">Adresse*</label>
              <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['address'].errors }" />
              <div *ngIf="submitted && fi['address'].errors" class="invalid-feedback">
                <div *ngIf="fi['address'].errors['required']">Ce champ est obligatoire</div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="addresscpt1">Adresse complément</label>
              <input type="text" formControlName="addresscpt1" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['addresscpt1'].errors }" />
            </div>
            <div class="form-group mb-2">
              <label for="addresscpt2">Adresse complément 2</label>
              <input type="text" formControlName="addresscpt2" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['addresscpt2'].errors }" />
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="postcode">Code postal*</label>
                  <input type="text" formControlName="postcode" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['postcode'].errors }" />
                  <div *ngIf="submitted && fi['postcode'].errors" class="invalid-feedback">
                    <div *ngIf="fi['postcode'].errors['required']">Ce champ est obligatoire</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="city">Ville*</label>
                  <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['city'].errors }" />
                  <div *ngIf="submitted && fi['city'].errors" class="invalid-feedback">
                    <div *ngIf="fi['city'].errors['required']">Ce champ est obligatoire</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="addresscpt2">Pays*</label>
              <select type="text" formControlName="country" class="form-select" [ngClass]="{ 'is-invalid': submitted && fi['country'].errors }" >
                <option value="">-- Choisissez un pays --</option>
                <option *ngFor="let country of appSettings.countries let i = index" [selected]="country === fi['country'].value">{{ country }}</option>
              </select>
              <div *ngIf="submitted && fi['country'].errors" class="invalid-feedback">
                <div *ngIf="fi['country'].errors['required']">Vous devez choisir un pays</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="phone">Téléphone portable*</label>
                  <input type="text" formControlName="phone"class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['phone'].errors }" />
                  <div *ngIf="submitted && fi['phone'].errors" class="invalid-feedback">
                    <div *ngIf="fi['phone'].errors['required']">Ce champ est obligatoire</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="landlinephone">Téléphone fixe</label>
                  <input type="text" formControlName="landlinephone" class="form-control" [ngClass]="{ 'is-invalid': submitted && fi['landlinephone'].errors }" />
                  <div *ngIf="submitted && fi['landlinephone'].errors" class="invalid-feedback">
                    <div *ngIf="fi['landlinephone'].errors['required']">Ce champ est obligatoire</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2 mt-2 text-center">
              <button [disabled]="loading" class="btn btn-primary">
                  Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingInterests">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInterests" aria-expanded="false" aria-controls="collapseOne">
          Mes centres d'intéret
        </button>
      </h2>
      <div id="collapseInterests" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionAccount">
        <div class="accordion-body">
          <form [formGroup]="formInterests" (ngSubmit)="onSubmitInterests()">
            <div class="row" *ngIf="interests">
              <div *ngFor="let interest of interests; let i = index" class="col-sm-6 col-md-3 text-center">
                <label class="checkbox-container mb-4">
                    <input type="checkbox" [formControlName]="'interests_'+ interest.id">
                    <div class="box">
                      <i class="mdi" [class]="getIconClass(interest.slug)"></i><br>
                      {{ interest.label }}
                    </div>
                </label>
              </div>
            </div>
            <div class="mb-2 mt-2 text-center">
              <button [disabled]="loading" class="btn btn-primary">
                  Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingCommunications">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCommunications" aria-expanded="false" aria-controls="collapseOne">
          Mes communications
        </button>
      </h2>
      <div id="collapseCommunications" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionAccount">
        <div class="accordion-body">
          <p>Dans le cadre de votre adhésion, vous recevez les invitations du Club par e-mail. </p>
          <p>Vous pouvez vous désabonner de ces communications via le lien dans les e-mails adressés ou en cochant le lien ci-dessous.</p>
        
          <form [formGroup]="formCommunications" (ngSubmit)="onSubmitCommunications()">
            <div class="form-check mb-4">
                <input class="form-check-input" id="newsCheckbox" type="checkbox" formControlName="newsOptout" value="" [ngClass]="{ 'is-invalid': submitted && fcom['newsOptout'].errors }">
                <label class="form-check-label" for="newsCheckbox">
                  Je ne souhaite plus recevoir les informations du Club par e-mail et je reconnais que le Club ne sera pas en capacité de me tenir informé(e) en tant que membre par ce biais.
                </label>
            </div>
            
            <div class="mb-2 mt-2 text-center">
              <button [disabled]="loading" class="btn btn-primary">
                  Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingInvoices">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInvoices" aria-expanded="false" aria-controls="collapseOne">
          Mes factures
        </button>
      </h2>
      <div id="collapseInvoices" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionAccount">
        <div class="accordion-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Montant (€)</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="invoices">
              <tr *ngFor="let invoice of invoices">
                <td>{{ formatterService.formatDate(invoice.createdAt, false) }}</td>
                <td>{{ invoice.amount }}</td>
                <td>
                  <span *ngIf="invoice.transaction.type === 'session'">{{ invoice.transaction.participation?.session.event.title }}</span>
                  <span *ngIf="invoice.transaction.type === 'renewal'">Renouvellement</span>
                </td>
                <td><a (click)="getInvoice(invoice.id)" class="text-primary" target="_blank"><i class="mdi mdi-download"></i></a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="formCredentials" (ngSubmit)="onSubmitCredentials()">
    <div class="form-group mb-2"></div>
  </form>
</div>
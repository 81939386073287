<div class="container pt-3">
  <div class="login-box">
    <div class="text-center">
      <h1 *ngIf="env.app === 'landrover'" class="brand-title big mb-2 mb-sm-4">LAND ROVER CLUB</h1>
      <h1 *ngIf="env.app === 'jaguar'" class="brand-title big mb-2 mb-sm-4">JAGUAR CLUB</h1>
    </div>
  </div>
  
  <div *ngIf="appSettings?.families" class="mb-5 boxes-families">
    <div *ngFor="let family of appSettings.families" class="box-family" [style]="generateBgImage( family.image )">
      <div class="bottom-content">
        <h2>{{ family.name }}</h2>
      </div>
    </div>
  </div>
      
  <div class="login-box">
    <div class="text-center">
      <div class="text-content big tag-line mb-3 mb-sm-5">
        Entrez dans un monde de privilèges !<br>
        Profitez dès maintenant d'offres exclusives et d'invitations à des événements inédits.
      </div>
      
      <div *ngIf="env.app === 'jaguar'" class="mb-4">
        Avant d’accéder à votre compte club sur le nouveau Jaguar Club, nous vous invitons à personnaliser vos centres d’intérêts et redéfinir le mot de passe de votre espace.
Nous vous invitons à consulter vos mails et à cliquer sur l’e-mail « Bienvenue sur le nouveau Jaguar Club ».
      </div>
      <div *ngIf="env.app === 'landrover'" class="mb-4">
        Avant d’accéder à votre compte club sur le nouveau Land Rover Club, nous vous invitons à personnaliser vos centres d’intérêts et redéfinir le mot de passe de votre espace.
Nous vous invitons à consulter vos mails et à cliquer sur l’e-mail « Bienvenue sur le nouveau Land Rover Club ».
      </div>
    </div>
  
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
        <div class="form-group mb-2">
            <label for="username">E-mail</label>
            <input type="email" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
            <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                <div *ngIf="f['username'].errors['required']">Ce champ est obligatoire</div>
            </div>
        </div>
        <div class="form-group mb-2">
            <label for="password">Mot de passe</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                <div *ngIf="f['password'].errors['required']">Ce champ est obligatoire</div>
            </div>
        </div>
        <div class="mb-4">
          <div class="row">
            <div class="col-sm-6">
              <a routerLink="/lost-password" class="">Mot de passe oublié ?</a>
            </div>
            <div class="col-sm-6 text-sm-end">
              <a [href]="'mailto:' + appSettings?.email" class="">Contactez le club</a><br>
              <a routerLink="/ask-validation-link" class="">Je n'ai pas reçu mon lien d'activation</a>
            </div>
          </div>
        </div>
        <div class="mb-2 text-center">
          <button [disabled]="loading" class="btn btn-primary">
              Connectez-vous
          </button>
        </div>
        <div class="mb-2 text-center">
          <a routerLink="/register" class="btn btn-secondary arrow">
              Inscrivez-vous
          </a>
        </div>
        <div class="mb-2 text-center">
          <a routerLink="/register-dealership" class="btn btn-secondary arrow">
              Concessionnaires : inscrivez-vous
          </a>
        </div>
    </form>
  </div>
</div>
<div class="container">
  <div class="row">
    <div *ngFor="let review of reviews" class="col-md-8 offset-md-2">
      <div class="box nocursor">
        <div class="content">
          <div class="description mb-2">{{ review.participation.session.event.title }}</div>
          <h2 class="title mb-1">{{ review.participation.user.publicname }}</h2>
          <div class="rating-stars-container">
            <span *ngFor="let a of [1,2,3,4,5]" class="star">
              <i *ngIf="a <= review.rate" class="mdi mdi-star"></i>
              <i *ngIf="a > review.rate"  class="mdi mdi-star-outline"></i>
            </span>
          </div>
          <div class="rating-comment" [innerHTML]="review.comment"></div>
        </div>
      </div>
    </div>
    <div *ngIf="reviews.length <= 0">
      Aucun témoignage disponible
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login.component';
import { LostPasswordComponent } from './account/lostpassword.component';
import { LostValidationLinkComponent } from './account/lostvalidationlink.component';
import { ResetPasswordComponent } from './account/resetpassword.component';
import { RegisterComponent } from './account/register.component';
import { RegisterDealershipComponent } from './account/registerdealership.component';
import { ValidationComponent } from './account/validation.component';
import { SettingsComponent } from './account/settings.component';
import { MyCarsComponent } from './account/mycars.component';
import { SetChassisComponent } from './account/setchassis.component';
import { AddCarComponent } from './account/addcar.component';
import { RenewalComponent } from './account/renewal.component';
import { CguComponent } from './account/cgu.component';
import { HomeComponent } from './home/home.component';
import { PageComponent } from './page/page.component';
import { SendReviewComponent } from './review/send-review.component';
import { OfferListComponent } from './offer/offer-list.component';
import { OfferSingleComponent } from './offer/offer-single.component';
import { ActuListComponent } from './actu/actu-list.component';
import { ActuSingleComponent } from './actu/actu-single.component';
import { ReviewListComponent } from './review/review-list.component';
import { EventListComponent } from './event/event-list.component';
import { MyEventListComponent } from './event/my-event-list.component';
import { EventSingleComponent } from './event/event-single.component';
import { EventSubscribeComponent } from './event/event-subscribe.component';
import { DelayedPaymentComponent } from './event/delayed-payment.component';
import { AlertComponent } from './_components/alert.component';
import { LoadingComponent } from './_components/loading.component';
import { CarouselComponent } from './_components/carousel.component';
import { PasswordFieldComponent } from './_components/passwordfield.component';
import { FilterComponent } from './_components/filter.component';

import { JwtInterceptor } from '@app/_helpers/jwt.interceptor';
import { ErrorInterceptor } from '@app/_helpers/error.interceptor';
import { LoadingInterceptor } from '@app/_helpers/loading.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LostPasswordComponent,
    LostValidationLinkComponent,
    ResetPasswordComponent,
    RegisterComponent,
    RegisterDealershipComponent,
    ValidationComponent,
    SettingsComponent,
    MyCarsComponent,
    AddCarComponent,
    SetChassisComponent,
    CguComponent,
    RenewalComponent,
    HomeComponent,
    AlertComponent,
    OfferListComponent,
    OfferSingleComponent,
    ActuListComponent,
    ActuSingleComponent,
    EventListComponent,
    MyEventListComponent,
    EventSingleComponent,
    EventSubscribeComponent,
    DelayedPaymentComponent,
    LoadingComponent,
    CarouselComponent,
    PasswordFieldComponent,
    FilterComponent,
    PageComponent,
    SendReviewComponent,
    ReviewListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="container">
  <div *ngIf="participation">
    <div class="row">
      <div class="col-md-8">
        <form *ngIf="context == 'add'" [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
          <div>
            <h2 class="h4 mb-4">Mon témoignage concernant "{{ participation.title }}"</h2>
            
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="rate">Note*</label>
                  <div class="form-stars-radio-container">
                    <div *ngFor="let rate of [1,2,3,4,5]" class="form-single-star-radio-container">
                      <input type="radio" formControlName="rate" [value]="rate" class="" [ngClass]="{ 'is-invalid': submitted && f['rate'].errors }">
                    </div>
                  </div>
                  <div *ngIf="selectedMark != null" class="form-stars-container">
                    <span *ngFor="let rate of [1,2,3,4,5]" class="form-single-star-container" (click)="changeMark(rate)">
                      <i *ngIf="rate <= selectedMark" class="mdi mdi-star"></i>
                      <i *ngIf="rate > selectedMark"  class="mdi mdi-star-outline"></i>
                    </span>
                  </div>
                  <div *ngIf="submitted && f['rate'].errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f['rate'].errors }">
                      <div *ngIf="f['rate'].errors['bad-mark']">Veuillez sélectionner une note</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <label for="comment">Commentaire*</label>
                  <textarea type="text" formControlName="comment" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['comment'].errors }"></textarea>
                </div>
              </div>
            </div>
            
            <button [disabled]="loading" class="btn btn-primary mt-4">
                Valider
            </button>
          </div>
          <div class="form-legend">* Champ obligatoire</div>
        </form>
        
        <div *ngIf="context == 'view'" class="box nocursor">
          <div class="content">
            <div class="description mb-2">{{ participation.title }}</div>
            <div class="rating-stars-container">
              <span *ngFor="let a of [1,2,3,4,5]" class="star">
                <i *ngIf="a <= participation.review.rate" class="mdi mdi-star"></i>
                <i *ngIf="a > participation.review.rate"  class="mdi mdi-star-outline"></i>
              </span>
            </div>
            <div class="rating-comment" [innerHTML]="participation.review.comment"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

import { Session } from '@model/session';
import { Event } from '@model/event';
import { EventService } from '../_services/event.service';
import { AccountService } from '../_services/account.service';
import { LoadingService } from '../_services/loading.service';
import { AlertService } from '../_services/alert.service';

declare let window: any;

@Component({
  selector: 'app-delayed-payment',
  templateUrl: './delayed-payment.component.html',
})
export class DelayedPaymentComponent implements OnInit {

  env = environment;
  loading = false;
  public transaction : any;

  constructor( private http: HttpClient, private alertService: AlertService, private router: Router, public accountService: AccountService, private route: ActivatedRoute, private loader: LoadingService, private formBuilder: FormBuilder )
  {
  	this.transaction = null;
  }

  ngOnInit() {
    let selectedId = this.route.snapshot.paramMap.get('hash');
    this.getParticipationSingle(selectedId);
  }
  
  getParticipationSingle(selectedHash: any) {
    this.accountService.getTransaction(selectedHash).subscribe((data: any) => { 
    	this.transaction = data;
    	
    	let url = this.mercanetHtmlToUrl(this.transaction.redirectionUrl, this.transaction.redirectionVersion, this.transaction.redirectionData);
      window.setTimeout(() => {
        // On traite le formulaire différemment si on est dans l'app mobile ou sur navigateur
        if( window.cordova ) {
          let inAppBrowserRef = window.cordova.InAppBrowser.open(url, '_blank', 'location=yes,hidden=no');
          
          inAppBrowserRef.addEventListener('exit', () => {
          	window.document.location.href = "#/";
          });
          
          // On détecte la redirection et on quitte le inAppBrowser
          inAppBrowserRef.addEventListener('loadstart', (params:any) => {
          	console.log(params.url)
          	if (params.url.includes("my-events")) {
                // On ferme la webview 
                inAppBrowserRef.close();
            }
          });
        } else {
        let document: any = window.document;
          document.redirectForm.submit();
        }
      }, 2000);
    }, 
    (data) => {
    	this.alertService.error(data.message);
    });
  }
  
  mercanetHtmlToUrl(url:any, version:any, data:any) {
    let HTML =
      '<form method="post" id="mercanetForm" action="' +
      url +
      '">' +
      '<input type="hidden" name="redirectionVersion" value="' +
      version +
      '">' +
      '<input type="hidden" name="redirectionData" value="' +
      data +
      '">' +
      '</form>' +
      '<script type="text/javascript">document.getElementById("mercanetForm").submit();</script>'

    return 'data:text/html;base64,' + btoa(HTML)
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { SettingsService } from '@app/_services/settings.service';

@Component({
  templateUrl: './validation.component.html',
})
export class ValidationComponent implements OnInit {
  env = environment;
  isValid = false;
  submitted = false;
  loading = false;
  form: FormGroup;
  currentStep = 1;
  interests: any = null;
  communications: any = null;
  appSettings:any = null;
  videoOverlay: any = true;
  videoElement: any = null;
  
  fields = {};
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private settingsService: SettingsService,
  ) {
  	this.fields = {
		  	gender: [''],
	      firstname: [''],
	      lastname: [''],
	      company: [''],
	      address: [''],
	      addresscpt1: [''],
	      addresscpt2: [''],
	      postcode: [''],
	      city: [''],
	      country: ['France'],
	      phone: [''],
	      landlinephone: [''],
	      password: ['', [/*Validators.required*/]],
        password2: [''],
        hash: [ this.route.snapshot.paramMap.get('hash') ],
        cgus: ['', Validators.required],
        newsOptout: [''],
	  };
	  this.form = this.formBuilder.group(this.fields);
	  
	  this.settingsService.settings.subscribe(x => { 
      this.appSettings = x;
    });
  }
  
	//convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  ngOnInit() {
	  this.isValid = true;
	  
	  // Integration dynamique de la vidéo
	  let videoElement:any = document.createElement('video');
	  videoElement.id = "video-player";
	  //videoElement.controls = 'true';
	  videoElement.style = "width:100%; display: block;";
	  videoElement.classList = "mb-4";
	  let sourceMp4Video = document.createElement('source');
	  sourceMp4Video.src = this.getPresentationVideoUrl();
	  videoElement.append( sourceMp4Video );
	  document.getElementById('video-presentation')?.appendChild( videoElement );
	  
	  this.videoElement = videoElement;
	  
	  /* désactivation temporaire */
    /*this.accountService.checkStep2( this.route.snapshot.paramMap.get('hash') )
      .pipe(first())
      .subscribe(
          (data:any) => {
          	this.f['gender'].setValue(data.gender);
          	this.f['firstname'].setValue(data.firstname);
          	this.f['lastname'].setValue(data.lastname);
          	this.f['company'].setValue(data.company);
          	this.f['address'].setValue(data.address);
          	this.f['addresscpt1'].setValue(data.addresscpt1);
          	this.f['addresscpt2'].setValue(data.addresscpt2);
          	this.f['postcode'].setValue(data.postcode);
          	this.f['city'].setValue(data.city);
          	this.f['country'].setValue(data.country);
          	this.f['phone'].setValue(data.phone);
          },
          error => {
            this.alertService.error( 'Lien de validation invalide' );
          }
    );*/
	  
	  this.settingsService.getInterests()
	    .subscribe(
	      (data:any) => {
	      	let interestsCheckBoxes:any = {};
	      	for( let i in data )
	      	{
	      		interestsCheckBoxes['interests_'+ data[i].id] = false;
	      	}
	      	
      		this.interests = data;
          this.fields = Object.assign(this.fields, interestsCheckBoxes);
          this.form = this.formBuilder.group(this.fields);
	      },
	      (err:any) => {
	          this.alertService.error('Centres d\'intérêt inaccessibles');
	      }
	  );
    
	  this.settingsService.getCommunications()
	    .subscribe(
	      (data:any) => {
	      	let communicationsCheckBoxes:any = {};
	      	for( let i in data )
	      	{
	      		communicationsCheckBoxes['communications_'+ data[i].id] = false;
	      	}
	      	
	      	this.communications = data;
	      	this.fields = Object.assign(this.fields, communicationsCheckBoxes);
          this.form = this.formBuilder.group(this.fields);
	      },
	      (err:any) => {
	          this.alertService.error('Communications inaccessibles');
	      }
	  );
  }
  
  stepFurther() {
  	// On valide les champs de l'étape 1 
  	if( this.currentStep == 1 )
  	{
  		let atLeastOneError = false;
  		this.submitted = true;
  		let fieldsToCheck = ['gender', 'firstname', 'lastname', 'address', 'postcode', 'postcode', 'city', 'country', 'phone', 'password', 'password2'];
  		for( let i in fieldsToCheck ) {
  			if( ! this.f[ fieldsToCheck[i] ].value ) {
  				atLeastOneError = true;
		  		this.f[ fieldsToCheck[i] ].setErrors({'required': true});
  			}
  		}
  		
  		// Mdp check
  		if( this.f['password'].value !== this.f['password2'].value )
  		{
  			atLeastOneError = true;
	  		this.f['password2'].setErrors({'not-the-same': true});
  		}
  		
  		if( !atLeastOneError ) {
  			this.submitted = false;
  			this.currentStep = this.currentStep + 1;
  		}
  	}
  	else
  	{
		  this.currentStep = this.currentStep + 1;
  	}
  }
  
  stepFormer() {
	  this.currentStep = this.currentStep - 1;
  }
  
  getIconClass( slug:any ) {
	  let map:any = {
		culture: "mdi-temple-hindu",
		gastronomy: "mdi-food-variant",
		wine: "mdi-glass-wine",
		music: "mdi-music",
		travel: "mdi-airplane",
		autosport: "mdi-car-sports",
		tennis: "mdi-tennis-ball",
		golf: "mdi-golf",
		horseriding: "mdi-horse-human",
		rugby: "mdi-rugby",
		email: "mdi-email-outline",
		letter: "mdi-mailbox",
		phone: "mdi-phone",
		push: "mdi-bell",
		sms: "mdi-message-alert",
	  }
	  
	  return map[ slug ];
  }
  
  onSubmit() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    this.accountService.saveAll( this.form.value )
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.alertService.success('Vos informations ont bien été enregistrées, vous pouvez désormais vous connecter');
          
          // Connexion auto
          this.accountService.login(data.username, this.f['password'].value).subscribe(
            data2 => {
                this.router.navigate(['']);
            },
            error => {
                this.alertService.error('Erreur inattendue');
                this.router.navigate(['/']);
                this.loading = false;
            }
          );
          
          //this.router.navigate(['/']);
          this.loading = false;
        },
        err => {
            this.alertService.error('Une erreur est survenue');
            console.log( err );
            
            this.currentStep = 1;
            
            // hightlight des erreurs
            for( let i in err.errors )
            {
              let error = {};
              Object.defineProperty( error, err.errors[i].message, {
              	  value: true,
              	  writable: false
              } );
              
              this.f[err.errors[i].property].setErrors( error );
            }
            
            this.loading = false;
        }
      );
	}
  
  getPresentationVideoUrl() {
    return this.env.videobase + 'presentation.mp4';
  }
  
  playVideo() {
  	this.videoOverlay = false;
  	
  	this.videoElement.controls = true;
  	this.videoElement.play();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { SettingsService } from '@app/_services/settings.service';
import { FormatterService } from '@app/_services/formatter.service';
import { Car } from '@model/car';

@Component({
  templateUrl: './mycars.component.html',
})
export class MyCarsComponent implements OnInit {
  
	public cars : Car[] = []; // Voitures
	
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public accountService: AccountService,
    private alertService: AlertService,
    public formatterService: FormatterService,
  ) {
    
  }
  
  ngOnInit() {
    this.getCarsList();
  }
  
  getCarsList() {
    this.accountService.getCars().subscribe((data: any) => {
      // mise à jour de la liste des offres
      let res = data;
      this.cars = [...res];
    });
  }
  
  sellCar(id:any) {
    this.accountService.sellCar(id).subscribe(
	    (data: any) => {
	    	this.alertService.success('Votre véhicule a bien été marqué comme "vendu"');
	    	let res = data;
	      this.cars = [...res];
	    },
	    (err: any) => {
	    	this.alertService.error('Une erreur est survenue');
	    },
    );
  }
  
  getChassisUrl(id: any)
  {
  	return "set-chassis/" + id;
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Event } from '../_models/event';

@Injectable({ providedIn: 'root' })
export class EventService {
    constructor(
        private router: Router,
        private http: HttpClient
    ) {}

    list(type = 'list', family:any = null): any {
    	let filters:any = {
    	  type: type == "list" ? "list" : "slider",
    	};
    	
    	if( family )
    	{
    		filters.family = family
    	}
    	
    	return this.http.get<Event[]>(`${environment.apibase}events`, { params: filters });
    }
    
    listForUser(): any {
    	return this.http.get<Event[]>(`${environment.apibase}my-events`);
    }
    
    getInvoice(id: any): any {
    	return this.http.get<Event[]>(`${environment.apibase}invoice/${id}`, {
    		observe: 'body',
    		responseType: 'blob' as any
    	});
    }
    
    single(id: any): any {
    	return this.http.get<Event[]>(`${environment.apibase}event/${id}`);
    }
    
    singleSession(id: any): any {
    	return this.http.get<Event[]>(`${environment.apibase}session/${id}`);
    }
    
    registerToSession(id: any, formValues:any): any {
    	return this.http.post(`${environment.apibase}session/participate/${id}`, formValues);
    }
    
    getFirstSession( sessions: any ) {
      if( sessions.length > 0 )
        return sessions[0];
    }
      
    countUnitsLeft( sessions: any ) {
      let globalCount = 0;
      if( sessions.length > 0 ) {
        for( let i in sessions ) {
          globalCount += sessions[i].nbUnitsLeft;
        }
      }
      
      return globalCount;
    }
}
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

import { Actu } from '@model/actu';
import { ActuService } from '../_services/actu.service';
import { LoadingService } from '../_services/loading.service';

@Component({
  selector: 'app-actu-single',
  templateUrl: './actu-single.component.html',
  //styleUrls: ['./actu-list.component.scss']
})
export class ActuSingleComponent implements OnInit {

  env = environment;
  public actu : Actu = new Actu;

  constructor( private http: HttpClient, private actuService: ActuService, private route: ActivatedRoute, private loader: LoadingService ) {}

  ngOnInit() {
    let selectedId = parseInt(this.route.snapshot.paramMap.get('id')!);
    this.getActuSingle(selectedId);
  }
  
  getActuSingle(selectedId: any) {
    this.actuService.single(selectedId).subscribe((data: any) => { this.actu = data });
  }
  
  getBackgroundThumbnailStyle( actu: Actu ) {
    return `background-image: url(${this.env.filebase}actus/${actu.picture});`;
  }
  
  getPictureUrl( actu: Actu ) {
    return `${this.env.filebase}actus/${actu.picture}`;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@services/page.service';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: [/*'./home.component.scss'*/]
})
export class PageComponent implements OnInit {

  page:any = null;
  private routerSubscription:any = null;
	
  constructor( private pageService: PageService, private route: ActivatedRoute, private router: Router ) { }

  ngOnInit(): void {
	  this.loadPage();
	  
	  this.routerSubscription = this.router.events.pipe(filter( (event:any) =>  event instanceof NavigationEnd === true  )).subscribe((event: Event) => {
			this.loadPage();
	  });
  }
  
  ngOnDestroy(): void {
	  this.routerSubscription.unsubscribe();
  }

  loadPage()
  {
	  this.pageService.single( this.route.snapshot.paramMap.get('slug') ).subscribe((data: any) => {
		  this.page = data;
	  });
  }

}

<div class="container">
  <h1 *ngIf="event.family?.name" class="brand-title mb-4">{{ event.family?.name }}</h1>
  <div *ngIf="event" class="box mb-3">
    <img [src]="getPictureUrl(event)" alt="" />
    <div class="content">
      <div class="title h4 m-0">{{ event.title }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 order-2 order-md-1">
      <div class="description" [innerHTML]="event.description"></div>
    </div>
    <div class="col-md-4 mb-4 order-1 order-md-2">
      <div class="sidebar">
        <h5>Sélectionnez la session</h5>
        <div *ngFor="let session of event.sessions" class="sidebox mb-4">
          <div class="meta-infos p-2">
            <div class="row">
              <div class="col-3">
                <div *ngIf="!session.dateEnd" class="key">Jour :</div>
                <div *ngIf="session.dateEnd" class="key">Début :</div>
              </div>
              <div class="col-9">
                <div class="value">
                  <div class="date">{{ formatterService.formatDate(session.date, false) }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <div class="key">Heure :</div>
              </div>
              <div class="col-9">
                <div class="value">{{ session.date|date:'H:mm' }}</div>
              </div>
            </div>
            <div *ngIf="session.dateEnd" class="row">
              <div class="col-3">
                <div class="key">Fin :</div>
              </div>
              <div class="col-9">
                <div class="value">
                  <div class="date">{{ formatterService.formatDate(session.dateEnd, false) }}</div>
                </div>
              </div>
            </div>
            <div *ngIf="session.dateEnd" class="row">
              <div class="col-3">
                <div class="key">Heure :</div>
              </div>
              <div class="col-9">
                <div class="value">{{ session.dateEnd|date:'H:mm' }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <div class="key">Lieu :</div>
              </div>
              <div class="col-9">
                <div class="value">
                  {{ session?.address}} <br />
                  <span [class]="{'d-none': session.addresscpt1 === ''}">{{ session?.addresscpt1}}<br /></span> 
                  {{ session?.postcode}} <br />
                  {{ session?.city}}
                </div>
              </div>
            </div>
          </div>
          <div class="meta-infos-supp p-2">
            <div *ngIf="session.type == 'seat'" class="item">Nombre de places max par membre : {{ session.maxUnitsByPerson }}</div>
            <div *ngIf="session.type == 'pack'" class="item">Nombre de packs max par membre : {{ session.maxUnitsByPerson }}</div>
            <div *ngIf="session.type == 'pack'" class="item">Nombre de sièges par pack : {{ session.maxSeatsByPack }}</div>
            <div class="item">Nombre d'unités restantes : {{ session.nbUnitsLeft }}</div>
            <div class="item">
              Prix <span *ngIf="session.type == 'seat'">par personne</span> : 
              <span *ngIf="session.price > 0; else freeSession">{{ session.price }}€</span>
              <ng-template #freeSession>
                Gratuit
              </ng-template>
            </div>
          </div>
          <div class="meta-status">
            <div *ngIf="session.statusForUser == 'open'" [class]="session.statusForUser" class="btn btn-primary w-100" routerLink="/event/subscribe/{{ session.id }}" >S'inscrire</div>
            <div *ngIf="session.statusForUser == 'registerable'" [class]="session.statusForUser" class="btn btn-primary w-100" routerLink="/event/subscribe/{{ session.id }}">S'inscrire sur liste d'attente</div>
            <div *ngIf="session.statusForUser == 'registered'" [class]="session.statusForUser" class="btn btn-success w-100">Inscrit</div>
            <div *ngIf="session.statusForUser == 'waiting'" [class]="session.statusForUser" class="btn btn-warning w-100">Sur liste d'attente</div>
            <div *ngIf="session.statusForUser == 'pending-payment'" [class]="session.statusForUser" class="btn btn-warning w-100">Paiement en attente</div>
            <div *ngIf="session.statusForUser == 'full'" [class]="session.statusForUser" class="btn btn-danger w-100">Session complète</div>
            <div *ngIf="session.statusForUser == 'refused'" [class]="session.statusForUser" class="btn btn-danger w-100">Refusé</div>
            <div *ngIf="session.statusForUser == 'limited'" [class]="session.statusForUser" class="btn btn-warning w-100">Votre compte est limité à une seule inscription</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="top-bar">
  <nav class="brand-bar py-2">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <a *ngIf="env.app == 'jaguar'" class="brand" routerLink="/">JAGUAR CLUB</a>
          <a *ngIf="env.app == 'landrover'" class="brand" routerLink="/">LAND ROVER CLUB</a>
        </div>
        <div class="col-auto">
          <div class="logo-bar-menu d-flex align-items-center">
            <div class="dropdown" *ngIf="user?.token">
              <button class="btn dropdown-toggle me-2" type="button" (click)="openSearchForm()">
                <i class="icon search"></i>
              </button>
              <button class="btn dropdown-toggle me-2" type="button" id="dropdownMenuAccount" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="icon personal"></i>
              </button>
              <ul class="dropdown-menu p-0" aria-labelledby="dropdownMenuAccount">
                <li>
                  <div class="dropdown-account-item">
                    Bonjour {{ user?.firstname }} {{ user?.lastname }}
                  </div>
                </li>
                <li>
                  <div class="dropdown-validity-item" [innerHTML]="expiryText"></div>
                </li>
                <li><a class="dropdown-item" routerLink="/my-account/infos">Mes informations</a></li>
                <li><a class="dropdown-item" routerLink="/my-events">Mes événements</a></li>
                <li><a class="dropdown-item" routerLink="/my-cars">Mes véhicules</a></li>
                <li><a class="dropdown-logout-item" href="#" (click)="logout()"><i class="mdi mdi-logout-variant"></i> Déconnexion</a></li>
              </ul>
              <button class="btn dropdown-toggle" type="button" id="dropdownMenuHelp" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="icon help"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuHelp">
                <li><a class="dropdown-item" [href]="'mailto:'+ this.appSettings.email">Contacter l'équipe</a></li>
                <li *ngIf="this.env.app === 'landrover'"><a class="dropdown-item" href="https://www.landrover.fr/national-dealer-locator.html">Trouver un concessionnaire</a></li>
              </ul>
            </div>
            <span href="" (click)="toggleMenu()" class="open-menu-btn"><i class="d-md-none icon menu"></i></span>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="top-menu-bar" *ngIf="user?.token" [class]="{'opened': mobileMenuOpen == true}">
    <div class="container">
      <ul class="main-menu">
        <li *ngFor="let family of families"><a [routerLink]="createFamilyLink(family.code)" routerLinkActive="active">{{ family.name }}</a></li>
        <li><a routerLink="/events" routerLinkActive="active">Tous les événements</a></li>
        <li><a routerLink="/offers" routerLinkActive="active">Offres</a></li>
        <li *ngIf="appSettings.shownews"><a routerLink="/actus" routerLinkActive="active">Actualités</a></li>
        <li><a routerLink="/reviews" routerLinkActive="active">Témoignages</a></li>
      </ul>
    </div>
  </div>
</div>

<alert></alert>
<app-loading></app-loading>

<div class="app-container">
  <router-outlet></router-outlet>
  
  <div class="searchbar" [class]="{'d-none': isSearchOpenened == false}">
    <form [formGroup]="searchForm" (ngSubmit)="sendSearch()" class="form-spec">
      <div class="row align-items-center">
        <div class="d-none d-sm-block col-auto">
          <i class="icon search"></i>
        </div>
        <div class="col">
          <input class="search-input" #search formControlName="q" type="text" />
        </div>
        <div class="col-auto">
          <button class="search-btn btn btn-primary" type="submit" ><span class="d-sm-none"><i class="icon search"></i></span><span class="d-none d-sm-block">rechercher</span></button>
        </div>
        <div class="col-auto">
          <i class="icon close" (click)="closeSearchForm()"></i>
        </div>
      </div>
    </form>
    <div *ngIf="searchResultCount != null" class="results-list">
      <div *ngIf="searchResultCount == 0" class="count-results">Aucun résultat trouvé</div>
      <div *ngIf="searchResultCount == 1" class="count-results">{{ searchResultCount }} résultat trouvé</div>
      <div *ngIf="searchResultCount > 1 " class="count-results">{{ searchResultCount }} résultats trouvés</div>
      <div *ngFor="let searchResult of searchResults" class="search-result" [routerLink]="searchResult.url">{{ searchResult.title }}</div>
    </div>
  </div>
  
  <div class="footer mt-5">
    <div class="container">
    </div>
    <div class="footer-menu-bar">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div *ngIf="appSettings" class="contact-bar">
              Pour contacter le {{ appSettings.clubname }} : <a [href]="'mailto:' + appSettings?.email" class="">{{ appSettings?.email }}</a><br>
              ou par téléphone au <a href="tel:{{ appSettings.phone }}">{{ appSettings.phone }}</a>
            </div>
            <ul class="page-links">
              <li><a routerLink="/page/membership-conditions">CGA</a></li>
              <li><a routerLink="/page/terms-of-sales">CGV</a></li>
              <li><a routerLink="/page/legal-notice">Mentions légales</a></li>
              <li><a routerLink="/page/cookie-policy">Cookies</a></li>
              <li><a routerLink="/page/privacy-policy">Confidentialité</a></li>
            </ul>
          </div>
          <div class="col-md-5">
            <div class="social-links">
              <h6>Suivez-nous</h6>
              <ul *ngIf="appSettings">
                <li><a target="_blank" [href]="appSettings.linkedinUrl"><i class="mdi mdi-linkedin"></i></a></li>
                <li><a target="_blank" [href]="appSettings.facebookUrl"><i class="mdi mdi-facebook"></i></a></li>
                <li><a target="_blank" [href]="appSettings.twitterUrl"><svg viewBox="0 0 24 24" aria-label="X" role="img" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18jsvk2 r-16y2uox r-lwhw9o"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg></a></li>
                <li><a target="_blank" [href]="appSettings.youtubeUrl"><i class="mdi mdi-youtube"></i></a></li>
                <li><a target="_blank" [href]="appSettings.instagramUrl"><i class="mdi mdi-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div class="legal-text text-center">{{ appSettings?.footerLegalText }}</div>
        
        <div class="mt-4 legal-text text-center">App Version : 2.1.4</div>
      </div>
    </div>
  </div>
</div>

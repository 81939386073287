import '@popperjs/core'
import 'bootstrap/dist/js/bootstrap.js'

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

declare let window: any;
declare let universalLinks: any;

if( window.cordova )
{
  document.addEventListener("deviceready", function(){
    platformBrowserDynamic().bootstrapModule(AppModule);
    
    // On traite le deep linking
    universalLinks.subscribe('launchedAppFromLink', (eventData:any) => {
       console.log('Did launch app from the link: ' + eventData.hash);
       document.location.href = '/#' + eventData.hash;
    });
  });
}
else
{
  platformBrowserDynamic().bootstrapModule(AppModule);
}

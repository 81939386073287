import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { of } from 'rxjs';
import { map, first, delay, filter } from 'rxjs/operators';

import { Event as MyEvent } from '@model/event';
import { EventService } from '@services/event.service';
import { FilterService } from '@services/filter.service';
import { SettingsService } from '@services/settings.service';

import { CarouselComponent } from '@components/carousel.component';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  //styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit, OnDestroy {

  env = environment;
  private eventsStore : MyEvent[] = []; // Event store
  public events : MyEvent[] = []; // Events affichées
  public sliderEvents : MyEvent[] = [];
  private filterChoiceSubscription: any;
  private routerSubscription: any;
  public selectedFamily:any = '';
  public appSettings:any = null;
  
  declare window:any;

  constructor( private http: HttpClient, public eventService: EventService, public filterService: FilterService, private route: ActivatedRoute, public settingsService: SettingsService, private router: Router ) {}

  ngOnInit(): void {
  	this.selectedFamily = this.route.snapshot.paramMap.get('family');
  	
    this.getEventsList();
    window.setTimeout( () => { this.getSliderEventsList(); }, 1000 );
    this.filterChoiceSubscription = this.filterService.getChoices().subscribe((choices) => {
      this._applyFilters(choices);
    });
    
    this.settingsService.settings.subscribe((settings) => {
    	this.appSettings = settings
    });
    
    this.routerSubscription = this.router.events.pipe(filter( (event:any) =>  event instanceof NavigationEnd === true  )).subscribe((event: Event) => {
    	this.selectedFamily = this.route.snapshot.paramMap.get('family');
    	this.getEventsList();
    });

  }
  
  ngOnDestroy(): void {
    this.filterChoiceSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
  
  getEventsList() {
    this.eventService.list('list', this.selectedFamily).subscribe((data: any) => {
      // mise à jour de la liste des offres
      let res = data.data;
      this.eventsStore = [...res];
      
      // On transmet les filtres disponibles au service filter
      let filters = [
                     {
                        name: "category",
                        label: "Catégorie",
                        classes: 'col-sm-4',
                        type: "select",
                        values: {
                        }
                     },
                     {
                        name: "region",
                        label: "Région",
                        classes: 'col-sm-4',
                        type: "select",
                        values: {
                        }
                     },
                     {
                        name: "status",
                        label: "Statut",
                        classes: 'col-sm-4',
                        type: "select",
                        values: {
                        }
                     },
      ];
      
      // Génération des filtres possibles
      for( let key in this.eventsStore ) {
        let event = this.eventsStore[key];
        // Catégories
        for( let categoryKey in event.categories ) {
          let category:any = event.categories[categoryKey];
          Object.defineProperty(filters[0].values, category.id, {
            value: category.label,
            writable: true,
            enumerable: true,
          });
        }
        
        // Région
        if( event.sessions ) {
         for( let sessionKey in event.sessions ) {
        	   let currentSession:any = event.sessions[sessionKey];
        	  if( currentSession.region ) {
	            let region:any = currentSession.region;
		          Object.defineProperty(filters[1].values, region.id, {
		            value: region.name,
		            writable: true,
		            enumerable: true,
		          });
        	  }
	        }
        }
        
        // Status
        let filterableStatus:any = {
            'available': 'Disponibles',
        };
        for( let statusKey in filterableStatus ) {
          let status:any = filterableStatus[statusKey];
          Object.defineProperty(filters[2].values, statusKey, {
            value: status,
            writable: true,
            enumerable: true,
          });
        }
      }
      
      this.filterService.initFilters( filters );
      
      this._applyFilters();
    });
  }
  
  private _applyFilters(choices: any|null = null)
  {
    // Aucun filtre actif
    let atLeastOneActive = false;
    if( choices !== null ) {
      for( let i in choices ) {
        if( choices[i] != '' ) {
          atLeastOneActive = true;
        }
      }
    }
    
    if( atLeastOneActive === false ) {
      this.events = this.eventsStore;
    } else {
      let filteredContent = [];
      for( let i in this.eventsStore )
      {
        let currentEvent = this.eventsStore[i];
        
        // filter categories
        let matchCat = false;
        if( choices.category != undefined )
        {
          for( let i in currentEvent.categories )
          {
            let currentCategory:any = currentEvent.categories[i];
            if( currentCategory.id == choices.category )
            {
              matchCat = true;
            }
          }
        }
        else {
          matchCat = true;
        }
        
        // filter Status
        let matchStatus = false;
        if( choices.status != undefined )
        {
          if( choices.status == "available" && this.eventService.countUnitsLeft(currentEvent.sessions) > 0 ) {
            matchStatus = true;
          }
        }
        else {
          matchStatus = true;
        }
        
        // filter Region
        let matchRegion = false;
        if( choices.region != undefined )
        {
        	if( currentEvent.sessions ) {
        		for( let sessionKey in currentEvent.sessions ) {
        			let currentSession = currentEvent.sessions[sessionKey];
        			if( currentSession.region )
        			{
			          if( choices.region == currentSession.region.id ) {
			          	matchRegion = true;
			          }
        			}
        		}
        	}
        }
        else {
        	matchRegion = true;
        }
        
        if( matchCat && matchStatus && matchRegion )
        {
          filteredContent.push( this.eventsStore[i] );
        }
      }
      
      this.events = filteredContent;
    }
    
  }
  
  getSliderEventsList() {
    this.eventService.list('slider', this.selectedFamily).subscribe((data: any) => { let res2 = data.data; this.sliderEvents = [...res2]; });
  }
  
  getThumbnailSrc( event: MyEvent ) {
    return `${this.env.filebase}events/${event.picture}`;
  }
  
  getBackgroundThumbnailStyle( event: MyEvent ) {
    return `background-image: url(${this.env.filebase}events/${event.picture});`;
  }
  
  generateBgImage( url:any ) {
    return `background-image: url(${url});`;
  }
  
  createFamilyLink(family:any)
  {
  	return '/events/' + family;
  }
  
  findFamilyByCode(family:any, property:any)
  {
  	if( this.appSettings.families.length > 0 )
  	{
  		for( let i in this.appSettings.families )
  		{
  			console.log(this.appSettings.families[i]);
  			if( this.appSettings.families[i].code == family )
  			{
  				return this.appSettings.families[i][property];
  			}
  		}
  	}
  	return '';
  }
}

<div class="container">
  <h1 class="mb-5">Nos conditions générales ont changées</h1>
  
  <div class="mb-4" *ngIf="privacyPage">
    <h2 class="mb-3">{{ privacyPage?.title }}</h2>
    <div class="scrollable" [innerHTML]="privacyPage.content"></div>
  </div>
  
  <div class="mb-4" *ngIf="membershipConditionPage">
    <h2 class="mb-3">{{ membershipConditionPage?.title }}</h2>
    <div class="scrollable" [innerHTML]="membershipConditionPage.content"></div>
  </div>
  
  <div class="mb-4" *ngIf="termsOfSalePage">
    <h2 class="mb-3">{{ termsOfSalePage?.title }}</h2>
    <div class="scrollable" [innerHTML]="termsOfSalePage.content"></div>
  </div>
  
  <form class="mt-4" [formGroup]="form" (ngSubmit)="onSubmit()">  
    <div class="form-check mb-4">
      <input class="form-check-input" id="cgusCheckbox" type="checkbox" formControlName="cgus" value="" [ngClass]="{ 'is-invalid': submitted && f['cgus'].errors }">
      <label class="form-check-label" for="cgusCheckbox">
        Je reconnais avoir pris connaissance des nouvelles conditions générales du Club.*
      </label>
      <div *ngIf="f['cgus'].errors" class="invalid-feedback">
          <div *ngIf="f['cgus'].errors['required']">Vous devez accepter les conditions générales du Club</div>
      </div>
    </div>
    
    <div class="mb-2 text-center">
      <button [disabled]="loading" class="btn btn-primary">
          Accepter
      </button>
    </div>
  </form>
</div>
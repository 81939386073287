<div class="container">
  <div class="login-box">
    <div class="text-center">
      <h1 class="title mb-2 mb-sm-4">J'ajoute un véhicule</h1>
    </div>
  
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
        <div>
          <div class="form-group mb-2">
              <label for="chassis">Mon numéro de chassis</label>
              <input type="text" formControlName="chassis" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['chassis'].errors }" />
              <div *ngIf="submitted && f['chassis'].errors" class="invalid-feedback">
                  <div *ngIf="f['chassis'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['chassis'].errors['bad-chassis-number']">Ce numéro de chassis n'est pas valide</div>
                  <div *ngIf="f['chassis'].errors['already-existing-chassis-number']">Ce numéro de chassis est déjà existant dans la base de donnée. Veuillez nous contacter afin de régler l'incident !</div>
              </div>
              <div class="small text-black-50">Le numéro de chassis est renseigné dans le champ E de votre carte grise.</div>
          </div>
          <div class="form-group mb-2">
              <label for="registration">Date de mise en circulation</label>
              <input type="date" (change)="changeMinDate()" [max]="getTodaysDate()" formControlName="registration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['registration'].errors }" />
              <div *ngIf="submitted && f['registration'].errors" class="invalid-feedback">
                  <div *ngIf="f['registration'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['registration'].errors['is-future-date']">Cette date ne peut pas être une date future</div>
              </div>
              <div class="small text-black-50">La date de mise en circulation est renseignée dans le champ B de votre carte grise.</div>
          </div>
          <div class="form-group mb-2">
              <label for="delivery">Date de livraison</label>
              <input type="date" [min]="minDate" [max]="getTodaysDate()" formControlName="delivery" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['delivery'].errors }" />
              <div *ngIf="submitted && f['delivery'].errors" class="invalid-feedback">
                  <div *ngIf="f['delivery'].errors['required']">Ce champ est obligatoire</div>
                  <div *ngIf="f['delivery'].errors['must-be-superior']">Cette date ne peut pas être antérieure à la date de mise en circulation</div>
                  <div *ngIf="f['delivery'].errors['is-future-date']">Cette date ne peut pas être une date future</div>
              </div>
              <div class="small text-black-50">La date de livraison est renseignée dans le champ I de votre carte grise.</div>
          </div>
        </div>
        <div class="form-group mb-2">
            <label for="model">Mon modèle</label>
            <select type="text" formControlName="model" class="form-select" [ngClass]="{ 'is-invalid': submitted && f['model'].errors }">
              <option *ngFor="let model of availableModels" [value]="model?.id">{{ model?.name }}</option>
            </select>
            <div *ngIf="submitted && f['model'].errors" class="invalid-feedback">
                <div *ngIf="f['model'].errors['required']">Vous devez choisir un modèle</div>
            </div>
        </div>
        <div class="form-group mb-4">
            <label for="dealership">Ma concession</label>
            <select type="text" formControlName="dealership" class="form-select" [ngClass]="{ 'is-invalid': submitted && f['dealership'].errors }">
              <option *ngFor="let dealership of availableDealerships" [value]="dealership?.id">{{ dealership?.name }}</option>
            </select>
            <div *ngIf="submitted && f['dealership'].errors" class="invalid-feedback">
                <div *ngIf="f['dealership'].errors['required']">Vous devez choisir un concessionnaire</div>
            </div>
        </div>
        <div class="mb-2 text-center">
          <button [disabled]="loading" class="btn btn-primary">
              Enregistrer
          </button>
        </div>
    </form>
  </div>
</div>
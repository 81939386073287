import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { of } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { Offer } from '@model/offer';
import { OfferService } from '@services/offer.service';
import { FilterService } from '@services/filter.service';

import { CarouselComponent } from '@components/carousel.component';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit {

  env = environment;
  private offersStore : Offer[] = []; // Offer store
  public offers : Offer[] = []; // Offers affichées
  public sliderOffers : Offer[] = [];
  private filterChoiceSubscription: any;

  constructor( private http: HttpClient, private offerService: OfferService, private filterService: FilterService ) {}

  ngOnInit(): void {
    this.getOffersList();
    this.getSliderOffersList();
    //
    this.filterChoiceSubscription = this.filterService.getChoices().subscribe((choices) => {
      this._applyFilters(choices);
    });
  }
  
  ngOnDestroy(): void {
    this.filterChoiceSubscription.unsubscribe();
  }
  
  getOffersList() {
    this.offerService.list().subscribe((data: any) => {
      // mise à jour de la liste des offres
      let res = data.data;
      this.offersStore = [...res];
      
      // On transmet les filtres disponibles au service filter
      let filters = [
                     {
                        name: "category",
                        label: "Catégorie",
                        type: "select",
                        classes: 'col-sm-6',
                        values: {
                        },
                     },
                     {
                        name: "region",
                        label: "Région",
                        type: "select",
                        classes: 'col-sm-6',
                        values: {
                        }
                     },
      ];
      
      // Génération des filtres possibles
      for( let key in this.offersStore ) {
    	  let offer = this.offersStore[key];
    	  // Catégories
        for( let categoryKey in offer.categories ) {
        	let category:any = offer.categories[categoryKey];
	        Object.defineProperty(filters[0].values, category.id, {
	          value: category.label,
	          writable: true,
	          enumerable: true,
	        });
        }
    	  // Région
        if( offer.region ) {
        	let region:any = offer.region;
	        Object.defineProperty(filters[1].values, region.id, {
	          value: region.name,
	          writable: true,
	          enumerable: true,
	        });
        }
      }
      
      this.filterService.initFilters( filters );
      
      this._applyFilters();
    });
  }
  
  private _applyFilters(choices: any|null = null)
  {
    // Aucun filtre actif
    let atLeastOneActive = false;
    if( choices !== null ) {
      for( let i in choices ) {
        if( choices[i] != '' ) {
          atLeastOneActive = true;
        }
      }
    }
    
    if( atLeastOneActive === false ) {
      this.offers = this.offersStore;
    } else {
      let filteredContent = [];
      for( let i in this.offersStore )
      {
        let currentOffer = this.offersStore[i];
        
        // filter categories
        let matchCat = false;
        if( choices.category != undefined )
        {
          for( let i in currentOffer.categories )
          {
            let currentCategory:any = currentOffer.categories[i];
            if( currentCategory.id == choices.category )
            {
              matchCat = true;
            }
          }
        }
        else {
          matchCat = true;
        }
        
        // filter Region
        let matchRegion = false;
        if( choices.region != undefined )
        {
          if( choices.region == currentOffer.region.id ) {
          	matchRegion = true;
          }
        }
        else {
        	matchRegion = true;
        }
        
        if( matchCat && matchRegion )
        {
          filteredContent.push( this.offersStore[i] );
        }
      }
      
      this.offers = filteredContent;
    }
  }
  
  getSliderOffersList() {
    this.offerService.list('slider').subscribe((data: any) => { let res2 = data.data; this.sliderOffers = [...res2]; });
  }
  
  getThumbnailSrc( offer: Offer ) {
    return `${this.env.filebase}offers/${offer.picture}`;
  }
  
  getBackgroundThumbnailStyle( offer: Offer ) {
    return `background-image: url(${this.env.filebase}offers/${offer.picture});`;
  }
}

<div class="container">
  <div *ngIf="checking === false" class="login-box">
    <div class="text-center">
      <h1 class="title mb-2 mb-sm-4">ABONNEMENT AU CLUB</h1>
      
      <div class="text-content big tag-line mb-3 mb-sm-5">
        Votre abonnement vous donnera accès à des événements exclusifs, organisés dans toute la France, à des tarifs préférentiels : rencontre et cours de cuisine avec des Chefs, cours de mixologie ou de cuisine italienne au Royal Monceau-Raffles Paris*****, visites privées d'expositions, road trip dans les vignobles de Bourgogne ou encore sur la Côte d’Azur, brunch au Caviar au comptoir de Caviar de Neuvic, dîner autour des millésimes anciens de la Maison Bollinger, afterwork au Lagardère Paris Racing… Des événements pensés pour vous faire vivre pleinement vos passions.
        <br><br>
        COMMANDE AVEC OBLIGATION DE PAIEMENT<br>
        Merci de préciser l’adresse de facturation.
Vous recevrez un e-mail de confirmation avec accès à votre facture. La facture émise sera également conservée et accessible sur votre espace membre durant 6 mois avant d'être supprimées.
      </div>
    </div>
    
    <form *ngIf="formPayment" name="redirectForm" method="POST" [action]="formPayment.redirectionUrl">
      <input type="hidden" name="redirectionVersion" [value]="formPayment.redirectionVersion" />
      <input type="hidden" name="redirectionData" [value]="formPayment.redirectionData" />
    </form>
  
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group mb-2">
              <label for="lastname">Nom*</label>
              <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-2">
              <label for="firstname">Prénom*</label>
              <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }">
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group mb-2">
              <label for="company">Raison sociale</label>
              <input type="text" formControlName="company" class="form-control">
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group mb-2">
              <label for="address">Adresse*</label>
              <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['address'].errors }">
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group mb-2">
              <label for="addresscpt1">Complément d'adresse</label>
              <input type="text" formControlName="addresscpt1" class="form-control">
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group mb-2">
              <label for="addresscpt2">Complément d'adresse 2</label>
              <input type="text" formControlName="addresscpt2" class="form-control">
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group mb-2">
              <label for="postcode">Code postal*</label>
              <input type="text" formControlName="postcode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['postcode'].errors }">
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group mb-2">
              <label for="city">Ville*</label>
              <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['city'].errors }">
            </div>
          </div>
          <div *ngIf="appSettings && userInfos" class="col-sm-12">
            <div class="form-group mb-2">
              <label for="country">Pays*</label>
              <select type="text" formControlName="country" class="form-select" [ngClass]="{ 'is-invalid': submitted && f['country'].errors }" >
                <option value="">-- Choisissez un pays --</option>
                <option *ngFor="let country of appSettings.countries let i = index" [selected]="country === f['country'].value">{{ country }}</option>
              </select>
            </div>
          </div>
          
        </div>
        <div>
          <div class="form-group mb-4">
              <label for="code">Code avantage</label>
              <input type="text" (keyup)="changeCoupon()"  formControlName="code" class="form-control mb-2" [ngClass]="{ 'is-invalid': submitted && f['code'].errors }" />
              <div *ngIf="submitted && f['code'].errors" class="invalid-feedback">
                  <div *ngIf="f['code'].errors['bad-coupon']">Ce numéro avantage est invalide</div>
              </div>
              <a (click)="validCoupon()" class="btn btn-secondary">
                  ACTIVER LE CODE AVANTAGE
              </a>
          </div>
          
          <h5 *ngIf="reductAmount == currentAmount" class="mb-4">Montant de votre règlement : {{ reductAmount }}€</h5>
          <h5 *ngIf="reductAmount != currentAmount" class="mb-4">Prix de base : <s>{{ currentAmount }}€</s><br>Montant de votre règlement : {{ reductAmount }}€</h5>
          
          <div class="form-group mb-5">
              <div class="form-check">
                <input [ngClass]="{ 'is-invalid': submitted && f['acceptance'].errors }" formControlName="acceptance"  class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate">
                <label class="form-check-label" for="flexCheckIndeterminate">
                  J'ai pris connaissance et accepte les <a routerLink="/page/terms-of-sales">conditions générales de vente *</a>
                </label>
                <div *ngIf="submitted && f['acceptance'].errors" class="invalid-feedback">
                    <div *ngIf="f['acceptance'].errors['required']">Vous devez accepter les CGVs</div>
                </div>
              </div>
          </div>
        </div>
          
        <div class="mb-2 text-center">
          <button (click)="logout()" class="btn btn-secondary me-3">
              Retour
          </button>
          <button [disabled]="loading" class="btn btn-primary">
              Payer
          </button>
        </div>
    </form>
  </div>
</div>
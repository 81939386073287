import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FilterService {
	private filtersSubject = new Subject()
	private choicesSubject = new Subject()
	public filter: Observable<any>;
	public choices: Observable<any>;
	public isNew = false;
	
	constructor ()
	{
		this.filter = this.filtersSubject.asObservable();
		this.choices = this.choicesSubject.asObservable();
	}
	
    initFilters(filter: any = {})
    {
    	this.isNew = true;
		this.filtersSubject.next( filter )
    }
    
    changeChoices( newChoices: any )
    {
      this.choicesSubject.next( newChoices )
    }
	
	getFilters()
	{
		return this.filter;
	}
	
	getChoices()
	{
		return this.choices;
	}
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user') || '{"token": ""}'));
        this.user = this.userSubject.asObservable();
    }

    public get tokens(): User {
        return this.userSubject.value;
    }

    login(username: any, password: any) {
        return this.http.post<User>(`${environment.apibase}login_check`, { username, password })
            .pipe(map(user => {
                this.saveToken(user);
                this.userSubject.next(user);
            }));
    }
    
    lostpassword(username: any) {
    	return this.http.post<any>(`${environment.apibase}account/request-lostpassword`, { username });
    }
    
    lostvalidationlink(username: any) {
    	return this.http.post<any>(`${environment.apibase}account/request-lostvalidationlink`, { username });
    }
    
    refresh(tokens: any) {
    	let refresh_token = tokens.refresh_token;
    	return this.http.post<any>(`${environment.apibase}token/renew`, { refresh_token });
    }
    
    saveToken(user: any) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.userSubject.next( user );
        localStorage.setItem('user', JSON.stringify(user));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(new User);
        this.router.navigate(['/login']);
    }

    register(formInfos: any) {
        return this.http.post(`${environment.apibase}register`, formInfos);
    }

    registerDealership(formInfos: any) {
        return this.http.post(`${environment.apibase}register-dealership`, formInfos);
    }
    
    addCar(formInfos: any) {
        return this.http.post(`${environment.apibase}add-car`, formInfos);
    }
    
    setChassis(id: any, formInfos: any) {
    		formInfos.id = id;
        return this.http.post(`${environment.apibase}set-chassis`, formInfos);
    }
    
    sellCar(id: any) {
        return this.http.post(`${environment.apibase}sell-car/${id}`, {});
    }
    
    getTransaction(hash: any) {
        return this.http.get(`${environment.apibase}transaction/${hash}`, {});
    }
    
    resetPassword(formInfos: any) {
        return this.http.post(`${environment.apibase}reset-password`, formInfos);
    }
    
    sendRenewal(formInfos: any) {
    	return this.http.post<any>(`${environment.apibase}account/renew`, formInfos);
    }
    
    acceptCgus() {
    	return this.http.post<any>(`${environment.apibase}accept-cgus`, {});
    }
    
    saveAll(formInfos: any) {
        return this.http.post(`${environment.apibase}account/save-all`, formInfos);
    }
    
    save(formInfos: any) {
        return this.http.post(`${environment.apibase}account/save-infos`, formInfos);
    }
    
    saveInterests(formInfos: any) {
        return this.http.post(`${environment.apibase}account/save-interests`, formInfos);
    }
    
    saveCommunications(formInfos: any) {
        return this.http.post(`${environment.apibase}account/save-communications`, formInfos);
    }
    
    savePassword(formInfos: any) {
        return this.http.post(`${environment.apibase}account/change-password`, formInfos);
    }
    
    deleteAccount() {
        return this.http.post(`${environment.apibase}account/delete`, {});
    }
    
    getInfos() {
        return this.http.get(`${environment.apibase}my-account-infos`);
    }
    
    checkStep2(hash: any) {
        return this.http.get(`${environment.apibase}step-2-get-infos/${hash}`);
    }
    
    getInvoices() {
        return this.http.get(`${environment.apibase}invoices`);
    }
    
    getCars() {
        return this.http.get(`${environment.apibase}my-cars`);
    }
    
    checkToken() {
    	// Le token existe
        if( this.userSubject.value.token ) {
            return true;
        }
        else
        {
        	return false;
        }
    }
    
    checkGaveTheirInfos() {
        if( this.userSubject.value.gaveTheirInfos == true ) {
            return true;
        }
        else
        {
        	return false;
        }
    }
    
    checkCoupon(code: any, price: any)
    {
    	return this.http.post(`${environment.apibase}coupon/check`, {code, price});
    }
    
    private parseJwt (token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}
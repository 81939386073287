<div class="container">
  <div *ngIf="session">
    <div class="row">
      <div class="col-md-8">
        <form *ngIf="formPayment" name="redirectForm" method="POST" [action]="formPayment.redirectionUrl">
          <input type="hidden" name="redirectionVersion" [value]="formPayment.redirectionVersion" />
          <input type="hidden" name="redirectionData" [value]="formPayment.redirectionData" />
        </form>
      
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-spec">
          <div [ngClass]="{'d-none': currentStep != 1}">
            <h2 class="h3">Informations des participants</h2>
            <div formArrayName="packs">
              <div *ngFor="let pack of packs().controls; let i = index" [formGroupName]="i" class="">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group mb-2">
                      <label for="firstname">Prénom*</label>
                      <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && getPack(i)['firstname'].errors }">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group mb-2">
                      <label for="lastname">Nom*</label>
                      <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && getPack(i)['lastname'].errors }">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class='mb-4'>
              <span *ngIf="addedPacks < session.maxUnitsByPerson" class="me-2">
                <span *ngIf="session.type === 'pack'" class="btn btn-primary" (click)="addPack()">Ajouter un pack</span>
                <span *ngIf="session.type === 'seat'" class="btn btn-primary" (click)="addPack()">Ajouter un participant</span>
              </span>
              <span *ngIf="addedPacks > 1">
                <span *ngIf="session.type === 'pack'" class="btn btn-secondary" (click)="removePack()">Supprimer un pack</span>
                <span *ngIf="session.type === 'seat'" class="btn btn-secondary" (click)="removePack()">Supprimer un participant</span>
              </span>
            </div>
            
            <div *ngIf="session.customFormText" class="mb-2" [innerHTML]="session.customFormText"></div>
            
            <div class="form-group mb-4">
              <label for="infos">Informations complémentaires</label>
              <textarea type="text" formControlName="infos" placeholder="Allergies, régime alimentaire, autres informations utiles" class="form-control"></textarea>
            </div>
            
            <a (click)="stepFurther()" class="btn btn-primary">
                Continuer
            </a>
          </div>
          <div [ngClass]="{'d-none': currentStep != 2}">
            <h2 *ngIf="session.price > 0" class="h3">Informations de facturation</h2>
            
            <div *ngIf="session.price > 0" class="row">
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="lastname">Nom*</label>
                  <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group mb-2">
                  <label for="firstname">Prénom*</label>
                  <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <label for="company">Raison sociale</label>
                  <input type="text" formControlName="company" class="form-control">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <label for="address">Adresse*</label>
                  <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['address'].errors }">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <label for="addresscpt1">Complément d'adresse</label>
                  <input type="text" formControlName="addresscpt1" class="form-control">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <label for="addresscpt2">Complément d'adresse 2</label>
                  <input type="text" formControlName="addresscpt2" class="form-control">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group mb-2">
                  <label for="postcode">Code postal*</label>
                  <input type="text" formControlName="postcode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['postcode'].errors }">
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group mb-2">
                  <label for="city">Ville*</label>
                  <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['city'].errors }">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <label for="country">Pays*</label>
                  <input type="text" formControlName="country" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['country'].errors }">
                </div>
              </div>
              
              <div class="form-group mb-4">
                  <label for="code">Code avantage</label>
                  <input (keyup)="changeCoupon()" type="text" formControlName="code" class="form-control mb-2" [ngClass]="{ 'is-invalid': submitted && f['code'].errors }" />
                  <a (click)="validCoupon()" class="btn btn-secondary">
                      ACTIVER LE CODE AVANTAGE
                  </a>
                  <div *ngIf="submitted && f['code'].errors" class="invalid-feedback">
                      <div *ngIf="f['code'].errors['bad-coupon']">Ce numéro avantage est invalide</div>
                  </div>
              </div>
              
            </div>
          
            <h4 *ngIf="reductAmount == currentAmount" class="mb-4">Montant de votre règlement : {{ reductAmount }}€</h4>
            <h4 *ngIf="reductAmount != currentAmount" class="mb-4">Prix de base : <s>{{ currentAmount }}€</s><br>Montant de votre règlement : {{ reductAmount }}€</h4>
            
            <div class="form-group mb-4">
                <div class="form-check">
                  <input [ngClass]="{ 'is-invalid': submitted && f['acceptance'].errors }" formControlName="acceptance"  class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate">
                  <label class="form-check-label" for="flexCheckIndeterminate">
                    J'ai pris connaissance et accepte les <a routerLink="/page/terms-of-sales">conditions générales de vente</a>
                  </label>
                  <div *ngIf="submitted && f['acceptance'].errors" class="invalid-feedback">
                      <div *ngIf="f['acceptance'].errors['required']">Vous devez accepter les CGV pour finaliser votre inscription.</div>
                  </div>
                </div>
            </div>
            
            
            <a (click)="stepFormer()" class="btn btn-secondary me-2">
                Retour
            </a>
            <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="reductAmount > 0">Payer</span>
                <span *ngIf="reductAmount <= 0">Valider</span>
            </button>
          </div>
          <div class="form-legend">* Champ obligatoire</div>
        </form>
        
      </div>
      <div class="col-md-4">
        <div class="sidebar">
          <h2 class="h4">Informations de la session</h2>
          <div class="sidebox mb-2">
            <div class="meta-infos p-2">
              <div class="row">
                <div class="col-3">
                   <div *ngIf="!session.dateEnd" class="key">Jour :</div>
                   <div *ngIf="session.dateEnd" class="key">Début :</div>
                </div>
                <div class="col-9">
                  <div class="value">
                    <div class="date">{{ formatterService.formatDate(session.date, false) }}</div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="key">Heure :</div>
                </div>
                <div class="col-9">
                  <div class="value">{{ session.date|date:'H:mm' }}</div>
                </div>
              </div>
              <div *ngIf="session.dateEnd" class="row">
                <div class="col-3">
                   <div class="key">Fin :</div>
                </div>
                <div class="col-9">
                  <div class="value">
                    <div class="date">{{ formatterService.formatDate(session.dateEnd, false) }}</div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="key">Heure :</div>
                </div>
                <div class="col-9">
                  <div class="value">{{ session.dateEnd|date:'H:mm' }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div class="key">Lieu :</div>
                </div>
                <div class="col-9">
                  <div class="value">
                    {{ session.address}} <br />
                    <span [class]="{'d-none': session.addresscpt1 === ''}">{{ session.addresscpt1}}<br /></span> 
                    {{ session.postcode}} <br />
                    {{ session.city}}
                  </div>
                </div>
              </div>
            </div>
            <div class="meta-infos-supp p-2">
              <div *ngIf="session.type == 'seat'" class="item">Nombre de places max par membre : {{ session.maxUnitsByPerson }}</div>
              <div *ngIf="session.type == 'pack'" class="item">Nombre de packs max par membre : {{ session.maxUnitsByPerson }}</div>
              <div *ngIf="session.type == 'pack'" class="item">Nombre de sièges par pack : {{ session.maxSeatsByPack }}</div>
              <div class="item">Nombre d'unités restantes : {{ session.nbUnitsLeft }}</div>
              <div class="item">
                Prix <span *ngIf="session.type == 'seat'">par personne</span> : 
                <span *ngIf="session.price > 0; else freeSession">{{ session.price }}€</span>
                <ng-template #freeSession>
                  Gratuit
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
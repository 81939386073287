import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '../_services/account.service';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class AuthAccountGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (this.accountService.checkToken()) {
          let currentPath = route.routeConfig?.path;
          
        	if( currentPath === 'login' )
        	{
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/']);
	          return false;
        	}
          
        }
        
        return true;
    }
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Offer } from '../_models/offer';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading$: Subject<boolean> = new Subject();
  
  constructor() {}
  
  show() {
    //console.log( "SHOW" );
    this.loading$.next(true);
  }
  
  hide() {
    //console.log( "HIDE" );
    this.loading$.next(false);
  }
}
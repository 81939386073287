import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from './_models/user';
import { AccountService } from './_services/account.service';
import { LoadingService } from './_services/loading.service';
import { SettingsService } from './_services/settings.service';
import { environment } from '@environments/environment';
import { Router, NavigationEnd, RouterState, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FormatterService } from '@app/_services/formatter.service';
import { SearchService } from '@app/_services/search.service';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first, catchError } from 'rxjs/operators';

declare let window:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	
	@ViewChild('search') searchElement: any;
	
  title = 'jlr-front';
  user?: User;
  expiryText: any = '';
  env = environment;
  loading$ = this.loader.loading$;
  isOpenMobileMenu = false;
  appSettings: any;
  families: any;
  mobileMenuOpen: any = false;
  searchForm: FormGroup;
  searchResultCount:any = null;
  searchResults: any = [];
  isSearchOpenened = false;
  isSearchRendered = false;
  
  constructor(private accountService: AccountService, public loader: LoadingService, public settingsService: SettingsService, private router: Router, formatterService: FormatterService, private titleService: Title, private formBuilder: FormBuilder, private searchService: SearchService) {
      this.accountService.user.subscribe((x:any) => { 
        this.user = x;
        
        let date = new Date( this.user?.expiry );
        let now = new Date();
        if( now < date ) {
	        this.expiryText = "Membre jusqu'au " + formatterService.formatDateSimple( this.user?.expiry );
        } else {
	        this.expiryText = "Compte Expiré";
        }
      });
      
      this.settingsService.settings.subscribe(x => {
        this.appSettings = x;
        this.families = x?.families;
      });
      
      this.router.events.pipe(filter( (event:any) =>  event instanceof NavigationEnd === true  )).subscribe((event) => {
  			this.hideMenu();
  			
  			this.closeSearchForm();
  			
  			// Analytics
  			const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        this.titleService.setTitle(title);
  			gtag('event', 'page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: document.location.href
        });
  	  });
      
      this.searchForm = this.formBuilder.group({
        q: ['']
  		});
  }
  
  ngOnInit()
  {
  	this.settingsService.getAppSettings().subscribe();
  	
 		// Subscribe to push notifications
    if( window.cordova )
    {
      window.cordova.plugins.firebase.messaging.getToken().then((token:any) => {
        console.log("Got device token: ", token); // Just for testing
	    });
      
      // Redirection automatique des click sur push
      window.cordova.plugins.firebase.messaging.onBackgroundMessage((payload:any) => {
      	window.document.location.href = '#/' + payload['redirect'];
      });
      
   		// Demande d'autorisation IOS
      window.cordova.plugins.firebase.messaging.requestPermission({forceShow: false}).then(() => {
      	console.log( "Push messaging is allowed" );
      });

  	}

  }
  
  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  logout() {
      this.accountService.logout();
  }
  
  openMobileMenu()
  {
	  this.isOpenMobileMenu = false;
  }
  
  createFamilyLink(family:any)
  {
  	return '/events/' + family;
  }
  
  toggleMenu() {
  	this.mobileMenuOpen = !this.mobileMenuOpen;
  }
  
  hideMenu() {
  	this.mobileMenuOpen = false;
  }
  
  sendSearch() {
  	
 		// stop here if form is invalid
  	if (this.searchForm.invalid) {
  	   return;
  	}
 		
  	this.searchService.search( this.searchForm.value.q )
    .pipe(first())
    .subscribe(
      (data:any) => {
      	this.searchResultCount = data.results.length;
        this.searchResults = data.results;
      },
      (err:any) => {
      	
      }
    );
  }
  
	//convenience getter for easy access to form fields
  get f() { return this.searchForm.controls; }
  
  openSearchForm() {
  	this.isSearchOpenened = true;
  	this.f['q'].setValue('');
  	
  	setTimeout(()=>{
	  	this.searchElement.nativeElement.focus();
    },300);
  }
  
  closeSearchForm() {
  	this.isSearchOpenened = false;
  	this.searchResultCount = null;
  }
}

<div class="container">
  <a routerLink="/events" class="btn btn-secondary arrow return mb-5">Retour</a>

  <h1 class="title mb-3">Mes événements club</h1>

  <div *ngIf="participations.length > 0; else noResult" class="row">
    <div *ngFor="let participation of participations" class="col-sm-6 col-md-4">
      <div class="box nocursor">
        <div class="thumbnail">
          <img *ngIf="participation.session.event" [src]="getThumbnailSrc(participation.session.event)" alt="" />
        </div>
        <div class="content">
          <a class="title h4" [routerLink]="['/event', participation.session.event?.id]">{{ participation.session?.event?.title }}</a>
          <div class="row">
            <div class="col-sm-12 mb-2">
              <div class="description">
                Le {{ participation.session?.date|date:'dd/MM/y' }}
              </div>
            </div>
          </div>
          <div class="status mb-2">
            <div *ngIf="participation.status == 'registered'" [class]="participation.status" class="btn btn-success w-100">Inscrit</div>
            <div *ngIf="participation.status == 'waiting'" [class]="participation.status" class="btn btn-warning w-100">Sur liste d'attente</div>
            <div *ngIf="participation.status == 'refused'" [class]="participation.status" class="btn btn-danger w-100">Refusé</div>
            <div *ngIf="participation.status == 'pending-payment'" [class]="participation.status" class="btn btn-warning w-100">Paiement en attente</div>
          </div>
          <div class="actions">
            <a *ngIf="participation.status == 'registered' && !isFutureDate(participation.session.date)" [routerLink]="['/send-review', participation.id]" class="btn btn-secondary arrow">
              <span *ngIf="participation.review == null">Laisser un témoignage</span>
              <span *ngIf="participation.review != null">Voir mon témoignage</span>
            </a>
            <a *ngIf="participation.transaction?.invoice != null" class="btn btn-secondary arrow" (click)="getInvoice(participation.transaction.invoice.id)" >Télécharger ma facture</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noResult>
    Aucun événement trouvé
  </ng-template>
</div>